import axios from "axios";
import { fetchLogout } from "api";
import { notification } from "antd";

let isLogoutAlready = false;
axios.interceptors.response.use(
  function (response) {
    isLogoutAlready = false;
    if (response?.data?.error) {
      console.error(response?.data?.error?.message);
      return Promise.reject(response?.data?.error);
    }
    return Promise.resolve(response?.data);
  },
  function (error) {
    // console.log("error", error.response.data.error);
    const expires_at = Number(localStorage.getItem("token_expired"));
    const expires_now = new Date();
    const expires_now_getTime = Math.floor(expires_now.getTime() / 1000);

    if (expires_now_getTime > expires_at && !isLogoutAlready) {
      isLogoutAlready = true;
      notification["warning"]({
        message: "Token已經過期",
        description: "頁面將轉導至登入頁面，請重新登入",
      });
      setTimeout(() => {
        fetchLogout();
      }, 1000);
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(
  function (config) {
    // 從 localStorage 將 token 取出
    const token = localStorage.getItem("access_token");
    // 如果 token 存在的話，則帶入到 headers 當中
    if (token) {
      config.headers["AuthDog"] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const validateQueryString = function (method, data) {
  return method === "GET" ? data : null;
};

const validateRequestBody = function (method, data) {
  return method !== "GET" ? data : null;
};

const validateStatus = function (status) {
  return status == 200 || status == 400 || status == 500;
};

const setConfig = function (config) {
  const {
    baseURL = process.env.REACT_APP_BASE_URL,
    url,
    method = "GET",
    data = {},
    headers = {},
    mimeType = "",
    timeout = 1000 * 30,
    responseType = "JSON", // 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
  } = config;

  return {
    headers,
    mimeType,
    responseType,
    url,
    method,
    baseURL,
    timeout,
    params: validateQueryString(method, data),
    data: validateRequestBody(method, data),
    validateStatus,
  };
};

export const fetchApi = function (config) {
  const customizedConfig = setConfig(config);
  return axios(customizedConfig);
};
