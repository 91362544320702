import React, { useEffect, useState } from "react";
import {
  Form,
  Modal,
  Input,
  Switch,
  Radio,
  InputNumber,
  Divider,
  Row,
  Col,
  Table,
} from "antd";
import { Columns } from "./data";
import {
  BtnCreateAcct,
  Wrapper,
  FormTitle,
  DividerWrapper,
  SelectWrapper,
  Option,
} from "./Styled";
import { tabDisplayOptions, tabShowAdOptions, statusOptions } from "options";

const TradeListModal = ({
  pagination,
  isVisible,
  setIsVisible,
  tradeListData,
  total,
}) => {
  // const [form] = Form.useForm();
  const columns = Columns();
  const [radioValue, setRadioValue] = useState(0);
  const onChange = (e) => {
    console.log("Value", e);
    setRadioValue(e.target.value);
  };

  // useEffect(() => {
  //   form.resetFields();
  // }, [isVisible]);

  return (
    <Modal
      width={1200}
      centered={true}
      maskClosable={false}
      footer={null}
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
    >
      <Wrapper>
        <Table
          dataSource={tradeListData}
          columns={columns}
          pagination={{
            ...pagination,
            total,
          }}
        ></Table>
      </Wrapper>
    </Modal>
  );
};

export default TradeListModal;
