import { fetchApi } from "./config";
import globalStore from "redux/store";
import actions from "redux/action";

// 登入相關
// 獲取驗證碼信息
export function fetchVerifyId() {
  let params = {
    url: "/agent/captchaid",
    data: {},
    method: "",
  };
  return fetchApi(params);
}
// 獲取響應圖形驗證碼
export function fetchVerifyImg(id) {
  alert(id);
  let params = {
    url: `/agent/captcha?id=${id}`,
    data: {},
    method: "",
  };
  return fetchApi(params);
}

export function fetchLogin({ user_name, password, captcha_id, captcha_code }) {
  let params = {
    url: "/agent/login",
    data: {
      user_name,
      password,
      captcha_id,
      captcha_code,
    },
    method: "POST",
  };
  return fetchApi(params);
}

// 推廣鏈結
export function fetchPromoteDomain({ current = 1, pageSize, cpc }) {
  let params = {
    url: "/agent/promote_domain",
    data: {
      current,
      pageSize,
      cpc,
    },
  };
  return fetchApi(params);
}

export function fetchPostPromoteDomain(data) {
  let params = {
    url: "agent/promote_domain",
    data: data,
    method: "POST",
  };
  return fetchApi(params);
}

export function fetchPutPromoteDomain(data, id) {
  let params = {
    url: `agent/promote_domain/${id}`,
    data: data,
    method: "PUT",
  };
  return fetchApi(params);
}

export function fetchDeletePromoteDomain(id) {
  let params = {
    url: `agent/promote_domain/${id}`,
    data: {},
    method: "DELETE",
  };
  return fetchApi(params);
}

export function fetchBind2FA({ user_name, password, code_2fa }) {
  let params = {
    url: "/pub/current/g2fa",
    data: {
      user_name,
      password,
      code: code_2fa,
    },
    method: "POST",
  };
  return fetchApi(params);
}

// 個人資料
export function fetchMe() {
  let params = {
    url: "/agent/me",
    data: {},
  };
  return fetchApi(params);
}

// 變更密碼
export function fetchPutPassword({ old_password, new_password }) {
  let params = {
    url: `agent/password`,
    data: { old_password, new_password },
    method: "PUT",
  };
  return fetchApi(params);
}

// 變更交易密碼
export function fetchPutTradePassword({ old_password, new_password }) {
  let params = {
    url: `agent/money_password`,
    data: { old_password, new_password },
    method: "PUT",
  };
  return fetchApi(params);
}

// 收益明細(CPA)
export function fetchCpaDaily({ date_begin, date_end }) {
  let params = {
    url: "/agent/report/daily_cpa",
    data: {
      date_begin,
      date_end,
    },
  };
  return fetchApi(params);
}
// 收益明細(CPS)
export function fetchCpsDaily({ date_begin, date_end }) {
  let params = {
    url: "/agent/report/daily_cps",
    data: {
      date_begin,
      date_end,
    },
  };
  return fetchApi(params);
}
// 收益明細(CPC)
export function fetchCpcDaily({ date_begin, date_end, current, pageSize }) {
  let params = {
    url: "/agent/report/daily_cpc",
    data: {
      date_begin,
      date_end,
      current,
      pageSize,
    },
  };
  return fetchApi(params);
}
// 登出
export function fetchLogout(data) {
  globalStore.dispatch(actions.clearUser());

  let params = {
    url: "/api/Admin/Logout",
    data,
    method: "POST",
  };

  return fetchApi(params);
}
// 提現列表
export function fetchWithdraw({
  created_at_begin,
  created_at_end,
  current,
  pageSize,
  name,
}) {
  let params = {
    url: `agent/withdraw_order`,
    data: {
      current,
      pageSize,
      name,
      created_at_begin,
      created_at_end,
    },
    method: "GET",
  };
  return fetchApi(params);
}
// 提現申請
export function fetchPostWithdraw(data) {
  let params = {
    url: "agent/withdraw_order",
    data: data,
    method: "POST",
  };
  return fetchApi(params);
}
// 提現取消
export function fetchPutWithdraw(id) {
  let params = {
    url: `agent/withdraw_order/cancel/${id}`,
    data: {},
    method: "PUT",
  };
  return fetchApi(params);
}
// 首頁
export function fetchHomepage() {
  let params = {
    url: `agent/summary`,
    data: {},
  };
  return fetchApi(params);
}

//公告管理
export function fetchAnnounce({ current, pageSize, status, type }) {
  let params = {
    url: "announce",
    data: {
      current,
      pageSize,
      status,
      type,
    },
  };
  return fetchApi(params);
}

// 域名獲取
export function fetchCdn({
  current,
  pageSize,
  id,
  only_count,
  status,
  type,
  url,
}) {
  let params = {
    url: "/system/cdn",
    data: {
      current,
      pageSize,
      id,
      only_count,
      status,
      type,
      url,
    },
  };
  return fetchApi(params);
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// export function fetchUnbind2FA({ id }) {
//   let params = {
//     url: "/pub/current/g2fa",
//     data: {
//       user_id: id,
//     },
//     method: "DELETE",
//   };
//   return fetchApi(params);
// }

// // Tab
// export function fetchTabList({
//   current,
//   pageSize,
//   name,
//   site_id = process.env.REACT_APP_SITE_ID,
//   status,
// }) {
//   let params = {
//     url: "/cms/tab",
//     data: {
//       current,
//       pageSize,
//       name,
//       site_id,
//       status,
//     },
//   };
//   return fetchApi(params);
// }
// export function fetchCreateTab({
//   // desplay_type = "1",
//   iconui_template,
//   name,
//   nav_id,
//   seqno = 0,
//   show_ad,
//   show_iconui,
//   status,
// }) {
//   const seqNumber = Number(seqno);
//   let params = {
//     url: "/cms/tab",
//     data: {
//       // desplay_type,
//       iconui_template,
//       name,
//       nav_id,
//       seqno: seqNumber,
//       show_ad,
//       show_iconui,
//       status,
//     },
//     method: "POST",
//   };
//   return fetchApi(params);
// }
// // 標籤管理
// export function fetchUpdateTab({
//   id,
//   desplay_type,
//   iconui_template,
//   name,
//   nav_id,
//   seqno,
//   show_ad,
//   show_iconui,
//   status,
// }) {
//   const seqNumber = Number(seqno);
//   let params = {
//     url: `/cms/tab/${id}`,
//     data: {
//       desplay_type,
//       iconui_template,
//       name,
//       nav_id,
//       seqno: seqNumber,
//       show_ad,
//       show_iconui,
//       status,
//     },
//     method: "PUT",
//   };
//   return fetchApi(params);
// }

// export function fetchDeleteTab({ id }) {
//   let params = {
//     url: `/cms/tab/${id}`,
//     method: "DELETE",
//   };
//   return fetchApi(params);
// }

// // 片商管理
// export function fetchProducer({ current, pageSize, status, account, name }) {
//   let params = {
//     url: "/video/producer",
//     data: {
//       current,
//       pageSize,
//       status,
//       account,
//       name,
//     },
//   };
//   return fetchApi(params);
// }

// export function fetchPostProducer(data) {
//   let params = {
//     url: "video/producer",
//     data: data,
//     method: "POST",
//   };
//   return fetchApi(params);
// }
// export function fetchPutProducer({ id, account, name, status }) {
//   let params = {
//     url: `video/producer/${id}`,
//     data: {
//       // account, // 這個先暫時不用
//       name,
//       status,
//     },
//     method: "PUT",
//   };
//   return fetchApi(params);
// }
// export function fetchDeleteProducer(id) {
//   let params = {
//     url: `video/producer/${id}`,
//     data: {},
//     method: "DELETE",
//   };
//   return fetchApi(params);
// }
// // 演員管理
// export function fetchActor({
//   current,
//   pageSize,
//   name,
//   orig_name,
//   gender,
//   description,
//   status,
// }) {
//   let params = {
//     url: "video/actor",
//     data: {
//       current,
//       pageSize,
//       name,
//       orig_name,
//       gender,
//       description,
//       status,
//     },
//   };
//   return fetchApi(params);
// }
// export function fetchPostActor(data) {
//   let params = {
//     url: "video/actor",
//     data: data,
//     method: "POST",
//   };
//   return fetchApi(params);
// }
// export function fetchPutActor({ id, ...others }) {
//   let params = {
//     url: `video/actor/${id}`,
//     data: others,
//     method: "PUT",
//   };
//   return fetchApi(params);
// }
// export function fetchDeleteActor(id) {
//   let params = {
//     url: `video/actor/${id}`,
//     data: {},
//     method: "DELETE",
//   };
//   return fetchApi(params);
// }
// // 標籤管理
// export function fetchTopLevelTagOptions() {
//   let params = {
//     url: "video/tag",
//     data: {
//       current: 1,
//       pageSize: 1000,
//       level: 1,
//     },
//   };
//   return fetchApi(params);
// }
// export function fetchTag({ current, pageSize, id, level, name, parent_name }) {
//   let params = {
//     url: "video/tag",
//     data: {
//       current,
//       pageSize,
//       id,
//       level,
//       name,
//       parent_name,
//     },
//   };
//   return fetchApi(params);
// }
// export function fetchPostTag(data) {
//   let params = {
//     url: "video/tag",
//     data: data,
//     method: "POST",
//   };
//   return fetchApi(params);
// }
// export function fetchPutTag(data) {
//   let params = {
//     url: `video/tag/${data?.id}`,
//     data: data,
//     method: "PUT",
//   };
//   return fetchApi(params);
// }
// export function fetchDeleteTag({ id }) {
//   let params = {
//     url: `video/tag/${id}`,
//     data: {},
//     method: "DELETE",
//   };
//   return fetchApi(params);
// }
// // 長視頻管理
// export function fetchVideo({
//   current,
//   pageSize,
//   state,
//   video_code,
//   title,
//   payment_type,
//   category_ids,
//   tag_ids,
//   producer_id,
//   region_id,
//   actor_ids,
//   sortby,
// }) {
//   let params = {
//     url: "video",
//     data: {
//       current,
//       pageSize,
//       state,
//       video_code,
//       title,
//       payment_type,
//       category_ids,
//       tag_ids,
//       producer_id,
//       region_id,
//       actor_ids,
//       sortby,
//     },
//   };
//   return fetchApi(params);
// }

// export function fetchOffShelfVideo(id) {
//   let params = {
//     url: `video/off_shelf/${id}`,
//     data: {},
//     method: "PUT",
//   };
//   return fetchApi(params);
// }
// export function fetchOnShelfVideo(id) {
//   let params = {
//     url: `video/on_shelf/${id}`,
//     data: {},
//     method: "PUT",
//   };
//   return fetchApi(params);
// }
// export function fetchPutVideo(data, id) {
//   let params = {
//     url: `video/${id}`,
//     data: data,
//     method: "PUT",
//   };
//   return fetchApi(params);
// }
// export function fetchDeleteVideo(id) {
//   let params = {
//     url: `video/${id}`,
//     data: {},
//     method: "DELETE",
//   };
//   return fetchApi(params);
// }
// // VideoCategory
// export function fetchVideoCategory({ current, pageSize, name }) {
//   let params = {
//     url: `video/category`,
//     data: {
//       current,
//       pageSize,
//       name,
//     },
//     method: "GET",
//   };
//   return fetchApi(params);
// }

// export function fetchPostVideoCategory(data) {
//   let params = {
//     url: "video/category",
//     data: data,
//     method: "POST",
//   };
//   return fetchApi(params);
// }
// export function fetchPutVideoCategory(data, id) {
//   let params = {
//     url: `video/category/${id}`,
//     data: data,
//     method: "PUT",
//   };
//   return fetchApi(params);
// }

// export function fetchDeleteVideoCategory(id) {
//   let params = {
//     url: `video/category/${id}`,
//     data: {},
//     method: "DELETE",
//   };
//   return fetchApi(params);
// }

// // VideoRegion
// export function fetchVideoRegion({ current, pageSize, name }) {
//   let params = {
//     url: `video/region`,
//     data: {
//       current,
//       pageSize,
//       name,
//     },
//     method: "GET",
//   };
//   return fetchApi(params);
// }

// export function fetchPostVideoRegion(data) {
//   let params = {
//     url: "video/region",
//     data: data,
//     method: "POST",
//   };
//   return fetchApi(params);
// }
// export function fetchPutVideoRegion(data, id) {
//   let params = {
//     url: `video/region/${id}`,
//     data: data,
//     method: "PUT",
//   };
//   return fetchApi(params);
// }

// export function fetchDeleteVideoRegion(id) {
//   let params = {
//     url: `video/region/${id}`,
//     data: {},
//     method: "DELETE",
//   };
//   return fetchApi(params);
// }

// // 金剛區
// export function fetchIconui({ current, pageSize, id, name, status, tab_id }) {
//   let params = {
//     url: "/cms/iconui",
//     data: {
//       current,
//       pageSize,
//       id,
//       name,
//       status,
//       tab_id,
//     },
//   };
//   return fetchApi(params);
// }
// export function fetchPostIconui({
//   icon_id,
//   name,
//   seqno,
//   site_id = process.env.REACT_APP_SITE_ID,
//   status,
// }) {
//   const seqNumber = Number(seqno);
//   let params = {
//     url: "/cms/iconui",
//     data: {
//       icon_id,
//       name,
//       seqno: seqNumber,
//       site_id,
//       status,
//     },
//     method: "POST",
//   };
//   return fetchApi(params);
// }
// export function fetchPutIconui({ id, icon_id, name, seqno, site_id, status }) {
//   const seqNumber = Number(seqno);
//   let params = {
//     url: `/cms/iconui/${id}`,
//     data: {
//       icon_id,
//       name,
//       seqno: seqNumber,
//       site_id,
//       status,
//     },
//     method: "PUT",
//   };
//   return fetchApi(params);
// }
// export function fetchDeleteIconui({ id }) {
//   let params = {
//     url: `/cms/iconui/${id}`,
//     method: "DELETE",
//   };
//   return fetchApi(params);
// }

// // 發佈管理
// export function fetchPublish({ current, pageSize, site_id, status }) {
//   let params = {
//     url: "cms/publish",
//     data: {
//       current,
//       pageSize,
//       site_id,
//       status,
//     },
//   };
//   return fetchApi(params);
// }
// export function fetchPublishResult({ site_id }) {
//   let params = {
//     url: "cms/publish/result",
//     data: {
//       site_id,
//     },
//   };
//   return fetchApi(params);
// }
// export function fetchPostPublish(data) {
//   let params = {
//     url: "cms/publish",
//     data: data,
//     method: "POST",
//   };
//   return fetchApi(params);
// }

// // Nav
// export function fetchNavOptions() {
//   let params = {
//     url: "/cms/nav",
//     data: {
//       current: 1,
//       pageSize: 1000,
//     },
//   };
//   return fetchApi(params);
// }
// export function fetchNavList({
//   current,
//   pageSize,
//   name,
//   site_id = process.env.REACT_APP_SITE_ID,
//   status,
// }) {
//   let params = {
//     url: "/cms/nav",
//     data: {
//       current,
//       pageSize,
//       name,
//       site_id,
//       status,
//     },
//   };
//   return fetchApi(params);
// }
// export function fetchCreateNav({
//   icon_id,
//   name,
//   seqno,
//   site_id = process.env.REACT_APP_SITE_ID,
//   status,
// }) {
//   const seqNumber = Number(seqno);
//   let params = {
//     url: "/cms/nav",
//     data: {
//       icon_id,
//       name,
//       seqno: seqNumber,
//       site_id,
//       status,
//     },
//     method: "POST",
//   };
//   return fetchApi(params);
// }
// export function fetchUpdateNav({ id, icon_id, name, seqno, site_id, status }) {
//   const seqNumber = Number(seqno);
//   let params = {
//     url: `/cms/nav/${id}`,
//     data: {
//       icon_id,
//       name,
//       seqno: seqNumber,
//       site_id,
//       status,
//     },
//     method: "PUT",
//   };
//   return fetchApi(params);
// }
// export function fetchDeleteNav({ id }) {
//   let params = {
//     url: `/cms/nav/${id}`,
//     method: "DELETE",
//   };
//   return fetchApi(params);
// }

// // Admin Account
// export function fetchAdminUserList({
//   current,
//   pageSize,
//   queryValue, // 模糊查詢使用
//   roleIDs,
//   status,
//   user_name,
// }) {
//   let params = {
//     url: "/users",
//     data: {
//       current,
//       pageSize,
//       queryValue,
//       roleIDs,
//       status,
//       user_name,
//     },
//   };
//   return fetchApi(params);
// }
// export function fetchCreateAdminUser({
//   email,
//   password,
//   phone,
//   status,
//   user_name,
// }) {
//   let params = {
//     url: "/users",
//     data: {
//       email,
//       password,
//       phone,
//       status,
//       user_name,
//     },
//     method: "POST",
//   };
//   return fetchApi(params);
// }
// export function fetchUpdateAdminUser({
//   id,
//   email,
//   password,
//   phone,
//   status,
//   user_name,
// }) {
//   let params = {
//     url: `/users/${id}`,
//     data: {
//       email,
//       password,
//       phone,
//       status,
//       user_name,
//     },
//     method: "PUT",
//   };
//   return fetchApi(params);
// }
// export function fetchDeleteAdminUser({ id }) {
//   let params = {
//     url: `/users/${id}`,
//     method: "DELETE",
//   };
//   return fetchApi(params);
// }

// // Area
// export function fetchAreaList({
//   current = 1,
//   pageSize = 1000,
//   title,
//   tab_id,
//   status,
// }) {
//   let params = {
//     url: "/cms/section",
//     data: {
//       current,
//       pageSize,
//       title,
//       tab_id,
//       status,
//     },
//   };
//   return fetchApi(params);
// }
// export function fetchPostAreaLis(data) {
//   let params = {
//     url: "/cms/section",
//     data: data,
//     method: "POST",
//   };
//   return fetchApi(params);
// }
// export function fetchPutAreaList(data, id) {
//   let params = {
//     url: `/cms/section/${id}`,
//     data: data,
//     method: "PUT",
//   };
//   return fetchApi(params);
// }
// export function fetchDeleteAreaList(id) {
//   let params = {
//     url: `/cms/section/${id}`,
//     method: "DELETE",
//   };
//   return fetchApi(params);
// }

// // 返佣列表
// export function fetchRebateList({
//   current = 1,
//   pageSize = 1000,
//   agent_user_name,
//   member_id,
//   created_at_begin,
//   created_at_end,
// }) {
//   let params = {
//     url: "/agent/rebate_log",
//     data: {
//       current,
//       pageSize,
//       agent_user_name,
//       member_id,
//       created_at_begin,
//       created_at_end,
//     },
//   };
//   return fetchApi(params);
// }

// // 推廣素材
// export function fetchPromoteSource({ current = 1, pageSize }) {
//   let params = {
//     url: "/agent/promote_resource",
//     data: {
//       current,
//       pageSize,
//     },
//   };
//   return fetchApi(params);
// }

// export function fetchPostPromoteSource(data) {
//   let params = {
//     url: "agent/promote_resource",
//     data: data,
//     method: "POST",
//   };
//   return fetchApi(params);
// }

// export function fetchPutPromoteSource(data, id) {
//   let params = {
//     url: `agent/promote_resource/${id}`,
//     data: data,
//     method: "PUT",
//   };
//   return fetchApi(params);
// }

// export function fetchDeletePromoteSource(id) {
//   let params = {
//     url: `agent/promote_resource/${id}`,
//     data: {},
//     method: "DELETE",
//   };
//   return fetchApi(params);
// }

// // 代理管理
// export function fetchAgent({
//   current = 1,
//   pageSize = 1000,
//   name,
//   user_name,
//   parent_user_name,
//   commission_state,
//   status,
//   created_at_begin,
//   created_at_end,
// }) {
//   let params = {
//     url: "/agent",
//     data: {
//       user_name,
//       current,
//       pageSize,
//       name,
//       parent_user_name,
//       commission_state,
//       status,
//       created_at_begin,
//       created_at_end,
//     },
//   };
//   return fetchApi(params);
// }

// export function fetchPostAgent(data) {
//   let params = {
//     url: "/agent",
//     data: data,
//     method: "POST",
//   };
//   return fetchApi(params);
// }

// export function fetchPostPrePayAgent(data) {
//   let params = {
//     url: "/agent/prepay",
//     data: data,
//     method: "POST",
//   };
//   return fetchApi(params);
// }

// // 編輯
// export function fetchPutAgent(data, id) {
//   let params = {
//     url: `/agent/${id}`,
//     data: data,
//     method: "PUT",
//   };
//   return fetchApi(params);
// }

// // 重置密碼
// export function fetchPutResetPassword(id) {
//   let params = {
//     url: `/agent/password/${id}`,
//     method: "PUT",
//   };
//   return fetchApi(params);
// }
// // 獲取交易流水
// export function fetchAgentTradeList({ current, pageSize, agent_id }) {
//   let params = {
//     url: "/agent/agent_transaction_log",
//     data: {
//       current,
//       pageSize,
//       agent_id,
//     },
//   };
//   return fetchApi(params);
// }

// // 會員管理
// export function fetchMemberList({
//   current = 1,
//   pageSize = 1000,
//   is_vip,
//   id,
//   show_id,
//   device_type,
//   agent_id,
//   name,
//   register_at_begin,
//   register_at_end,
// }) {
//   let params = {
//     url: "/member",
//     data: {
//       id,
//       show_id,
//       current,
//       pageSize,
//       is_vip,
//       device_type,
//       agent_id,
//       name,
//       register_at_begin,
//       register_at_end,
//     },
//   };
//   return fetchApi(params);
// }
// export function fetchPostMemberCoin(data) {
//   let params = {
//     url: "/member/coin",
//     data: data,
//     method: "POST",
//   };
//   return fetchApi(params);
// }
// export function fetchPostMemberVip({ id, forever, days }) {
//   let params = {
//     url: `/member/vip`,
//     data: {
//       id,
//       forever,
//       days,
//     },
//     method: "POST",
//   };
//   return fetchApi(params);
// }
// export function fetchPostMemberAgent(data) {
//   let params = {
//     url: `/member/bind_agent`,
//     data: data,
//     method: "POST",
//   };
//   return fetchApi(params);
// }
// export function fetchPutMember(data, id) {
//   let params = {
//     url: `/member/${id}`,
//     data: data,
//     method: "PUT",
//   };
//   return fetchApi(params);
// }

// // 商品管理
// // 金幣商品
// export function fetchProduct({ current, pageSize, product_type = 1 }) {
//   let params = {
//     url: "/product",
//     data: {
//       product_type,
//       current,
//       pageSize,
//     },
//   };
//   return fetchApi(params);
// }

// export function fetchPostProduct(data) {
//   let params = {
//     url: `/product`,
//     data: data,
//     method: "POST",
//   };
//   return fetchApi(params);
// }

// export function fetchPutProduct(data, id) {
//   let params = {
//     url: `/product/${id}`,
//     data: data,
//     method: "PUT",
//   };
//   return fetchApi(params);
// }

// export function fetchDeleteProduct(id) {
//   let params = {
//     url: `/product/${id}`,
//     method: "DELETE",
//   };
//   return fetchApi(params);
// }

// // VIP套餐
// export function fetchVIP({ current, pageSize, product_type = 2 }) {
//   let params = {
//     url: "/product",
//     data: {
//       product_type,
//       current,
//       pageSize,
//     },
//   };
//   return fetchApi(params);
// }

// export function fetchPostVIP(data) {
//   let params = {
//     url: `/product`,
//     data: data,
//     method: "POST",
//   };
//   return fetchApi(params);
// }

// export function fetchPutVIP(data, id) {
//   let params = {
//     url: `/product/${id}`,
//     data: data,
//     method: "PUT",
//   };
//   return fetchApi(params);
// }

// export function fetchDeleteVIP(id) {
//   let params = {
//     url: `/product/${id}`,
//     method: "DELETE",
//   };
//   return fetchApi(params);
// }

// // 副商品管理
// export function fetchSubProduct({ current, pageSize }) {
//   let params = {
//     url: "/product/subproduct",
//     data: {
//       current,
//       pageSize,
//     },
//   };
//   return fetchApi(params);
// }

// export function fetchPostSubProduct(data) {
//   let params = {
//     url: `/product/subproduct`,
//     data: data,
//     method: "POST",
//   };
//   return fetchApi(params);
// }

// export function fetchPutSubProduct(data, id) {
//   let params = {
//     url: `/product/subproduct/${id}`,
//     data: data,
//     method: "PUT",
//   };
//   return fetchApi(params);
// }

// export function fetchDeleteSubProduct(id) {
//   let params = {
//     url: `/product/subproduct/${id}`,
//     method: "DELETE",
//   };
//   return fetchApi(params);
// }

// // 視頻商品
// export function fetchVideoProduct({ current, pageSize }) {
//   let params = {
//     url: "/product/product_video",
//     data: {
//       current,
//       pageSize,
//     },
//   };
//   return fetchApi(params);
// }

// export function fetchPostVideoProduct(data) {
//   let params = {
//     url: `/product/product_video`,
//     data: data,
//     method: "POST",
//   };
//   return fetchApi(params);
// }

// export function fetchPutVideoProduct(data, id) {
//   let params = {
//     url: `/product/product_video/${id}`,
//     data: data,
//     method: "PUT",
//   };
//   return fetchApi(params);
// }

// export function fetchDeleteVideoProduct(id) {
//   let params = {
//     url: `/product/product_video/${id}`,
//     method: "DELETE",
//   };
//   return fetchApi(params);
// }

// // 訂單明細
// export function fetchOrderDetail({
//   current,
//   pageSize,
//   agent_id,
//   member_id,
//   product_id,
//   status,
//   transaction_id,
// }) {
//   let params = {
//     url: "/member/order",
//     data: {
//       current,
//       pageSize,
//       agent_id,
//       member_id,
//       product_id,
//       status,
//       transaction_id,
//     },
//   };
//   return fetchApi(params);
// }

// // 返回佣金
// export function fetchPutReturnRebate({ member_order_id, pay_order_id }) {
//   let params = {
//     url: `member_order/manual_recharge_rebate`,
//     data: {
//       member_order_id,
//       pay_order_id,
//     },
//     method: "PUT",
//   };
//   return fetchApi(params);
// }

// // 入款
// export function fetchPutReturnSuccess({ member_order_id, pay_order_id }) {
//   let params = {
//     url: `member_order/manual_recharge_success`,
//     data: {
//       member_order_id,
//       pay_order_id,
//     },
//     method: "PUT",
//   };
//   return fetchApi(params);
// }

// // 代理提現
// export function fetchAgentWithdraw({
//   current,
//   pageSize,
//   agent_id,
//   agent_name,
//   agent_user_name,
//   id,
//   status,
// }) {
//   let params = {
//     url: "/agent/withdraw_order",
//     data: {
//       current,
//       pageSize,
//       agent_id,
//       agent_name,
//       agent_user_name,
//       id,
//       status,
//     },
//   };
//   return fetchApi(params);
// }

// export function fetchPutAcceptWithDraw(id) {
//   let params = {
//     url: `/agent/withdraw_order/accept/${id}`,
//     data: {},
//     method: "PUT",
//   };
//   return fetchApi(params);
// }

// export function fetchPutDenyWithDraw(id) {
//   let params = {
//     url: `/agent/withdraw_order/deny/${id}`,
//     data: {},
//     method: "PUT",
//   };
//   return fetchApi(params);
// }

// // 支付類型
// export function fetchPayType({ current, pageSize, id, name, status }) {
//   let params = {
//     url: "/pay/type",
//     data: {
//       current,
//       pageSize,
//       id,
//       name,
//       status,
//     },
//   };
//   return fetchApi(params);
// }

// export function fetchPostPayType({ name, status }) {
//   let params = {
//     url: `/pay/type`,
//     data: {
//       name,
//       status,
//     },
//     method: "POST",
//   };
//   return fetchApi(params);
// }

// export function fetchPutPayType({ name, status }, id) {
//   let params = {
//     url: `/pay/type/${id}`,
//     data: { name, status },
//     method: "PUT",
//   };
//   return fetchApi(params);
// }

// export function fetchDeletePayType(id) {
//   let params = {
//     url: `/pay/type/${id}`,
//     method: "DELETE",
//   };
//   return fetchApi(params);
// }

// // 支付平台
// export function fetchPay({
//   current,
//   pageSize,
//   id,
//   key_type,
//   merch_id,
//   name,
//   pay_code,
//   provider_url,
//   return_ip,
//   return_url,
//   status,
// }) {
//   let params = {
//     url: "/pay/platform",
//     data: {
//       current,
//       pageSize,
//       id,
//       key_type,
//       merch_id,
//       name,
//       pay_code,
//       provider_url,
//       return_ip,
//       return_url,
//       status,
//     },
//   };
//   return fetchApi(params);
// }

// export function fetchPostPay({
//   key_type,
//   merch_id,
//   name,
//   pay_code,
//   provider_url,
//   private_key,
//   public_key,
//   return_ip,
//   return_url,
//   secret_key,
//   status,
// }) {
//   let params = {
//     url: `/pay/platform`,
//     data: {
//       key_type,
//       merch_id,
//       name,
//       pay_code,
//       provider_url,
//       private_key,
//       public_key,
//       return_ip,
//       return_url,
//       secret_key,
//       status,
//     },
//     method: "POST",
//   };
//   return fetchApi(params);
// }

// export function fetchPutPay(
//   {
//     key_type,
//     merch_id,
//     name,
//     pay_code,
//     provider_url,
//     private_key,
//     public_key,
//     return_ip,
//     return_url,
//     secret_key,
//     status,
//   },
//   id
// ) {
//   let params = {
//     url: `/pay/platform/${id}`,
//     data: {
//       key_type,
//       merch_id,
//       name,
//       pay_code,
//       provider_url,
//       private_key,
//       public_key,
//       return_ip,
//       return_url,
//       secret_key,
//       status,
//     },
//     method: "PUT",
//   };
//   return fetchApi(params);
// }

// export function fetchDeletePay(id) {
//   let params = {
//     url: `/pay/platform/${id}`,
//     method: "DELETE",
//   };
//   return fetchApi(params);
// }

// // 支付渠道
// export function fetchPayChannel({
//   current,
//   pageSize,
//   id,
//   name,
//   pay_id,
//   status,
//   type_id,
// }) {
//   let params = {
//     url: "/pay/channel",
//     data: {
//       current,
//       pageSize,
//       id,
//       name,
//       pay_id,
//       status,
//       type_id,
//     },
//   };
//   return fetchApi(params);
// }

// export function fetchPostPayChannel({
//   list,
//   amount_max,
//   amount_min,
//   name,
//   pay_id,
//   seqno,
//   status,
//   type_id,
// }) {
//   let params = {
//     url: `/pay/channel`,
//     data: {
//       list,
//       amount_max,
//       amount_min,
//       name,
//       pay_id,
//       seqno,
//       status,
//       type_id,
//     },
//     method: "POST",
//   };
//   return fetchApi(params);
// }

// export function fetchPutPayChannel(
//   { list, amount_max, amount_min, name, pay_id, seqno, status, type_id },
//   id
// ) {
//   let params = {
//     url: `/pay/channel/${id}`,
//     data: {
//       list,
//       amount_max,
//       amount_min,
//       name,
//       pay_id,
//       seqno,
//       status,
//       type_id,
//     },
//     method: "PUT",
//   };
//   return fetchApi(params);
// }

// export function fetchDeletePayChannel(id) {
//   let params = {
//     url: `/pay/channel/${id}`,
//     method: "DELETE",
//   };
//   return fetchApi(params);
// }
