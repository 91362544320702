import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import actions from "redux/action";
import { Base64 } from "js-base64";
import { useTranslation } from "react-i18next";
import { BlueButton, RedButton } from "styles/component";
import LogInPsdModal from "component/LogInPsdModal/index";
import TradePsdModal from "component/TradePsdModal";
import CreateModal from "page/WithdrawApply/component/CreateModal";
import { SyncOutlined } from "@ant-design/icons";
import {
  SuccessNotify,
  ErrorNotify,
} from "component/Notification/Notification";
import {
  Input,
  Typography,
  Form,
  Row,
  Col,
  Statistic,
  Card,
  Modal,
  Space,
  Button,
  message,
  List,
  notification,
} from "antd";
import {
  OutsideWrapper,
  FormWrapper,
  ApplyButton,
  RefreshButton,
  StatisticValue,
  ModalWrapper,
  MobileCard,
} from "./Styled";
import {
  fetchHomepage,
  fetchPutTradePassword,
  fetchAnnounce,
  fetchMe,
  fetchPutPassword,
  fetchWithdraw,
  fetchPostWithdraw,
} from "api/index";
import {
  selectSetTradePsd,
  selectSetLogInPsd,
  selectUserName,
  selectIsTradeVisible,
  selectIsLogInVisible,
  selectOldPsd, //登入頁面帶過來的舊密碼
  selectRecord,
} from "redux/selector/user";

export default function Home() {
  const { t } = useTranslation();
  const [form] = FormWrapper.useForm();
  const windowWidth = window.innerWidth;
  const [changePsdForm] = FormWrapper.useForm();
  const [tradeForm] = FormWrapper.useForm();
  const dispatch = useDispatch();
  const username = useSelector(selectUserName);
  // 提現彈窗是否可見
  const isTradeVisible = useSelector(selectIsTradeVisible);
  // 登入彈窗是否可見
  const isLogInVisible = useSelector(selectIsLogInVisible);
  // 1:第一次設定 , 2: 不是第一次設定
  const setTradePsdStatus = useSelector(selectSetTradePsd); // 提現密碼
  const setLogInPsdStatus = useSelector(selectSetLogInPsd); // 登入密碼
  const oldPassword = useSelector(selectOldPsd);
  const { Title } = Typography;
  const [tableData, setTableData] = useState([]);
  //申請提現資訊
  const [withdrawTableData, setWithdrawTableData] = useState([]);
  const [statisticData, setStatisticData] = useState({});
  // 公告彈窗
  const [isModalVisible, setIsModalVisible] = useState(!isTradeVisible);
  const [isChangePsdVisible, setIsChangePsdVisible] = useState(false);
  const record = useSelector(selectRecord);
  //申請提現彈窗
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  useEffect(() => {
    setIsModalVisible(!isLogInVisible);
  }, [isLogInVisible]);
  // const [isSetModalVisible, setIsSetModalVisible] = useState(false);
  const handleFetchList = () => {
    fetchHomepage().then((res) => {
      res.balance = Number(res.balance);
      res.today_commission = Number(res.today_commission);
      res.week_commission = Number(res.week_commission);
      setStatisticData(res);
    });
    fetchAnnounce({ current: 1, pageSize: 100 }).then((res) => {
      setTableData(res.data);
    });
    fetchMe().then((res) => {
      dispatch(actions.updateMode(res.mode));
      dispatch(actions.updateRecord(res));
      dispatch(actions.storeInviteCode(res.invite_code));
    });
    fetchWithdraw({ current: 1, pageSize: 999 }).then((response) => {
      setWithdrawTableData(response.data);
    });
  };
  const handleFetchData = () => {};
  // 首次設定提現密碼
  const handleFirstSetting = (params) => {
    let old_password = params.old_password;
    let new_password = params.new_password;
    fetchPutTradePassword({ old_password, new_password })
      .then((res) => {
        SuccessNotify("修改成功");
        // setIsVisible(false);
        dispatch(actions.onModalToggle(false));
        dispatch(actions.tradPsdStatus(2));
        tradeForm.resetFields();
        // setIsModalVisible(true);
      })
      .catch((error) => {
        ErrorNotify(error.message);
      });
  };
  // 首次設定登入密碼
  const handleFirstLogInSetting = (params) => {
    let old_password = "";
    if (setLogInPsdStatus !== 1) old_password = params.old_password;
    else {
      old_password = oldPassword;
    }
    let new_password = params.new_password;
    fetchPutPassword({ old_password, new_password })
      .then((res) => {
        SuccessNotify("修改成功");
        // setIsVisible(false);
        dispatch(actions.onLogInModalToggle(false));
        dispatch(actions.psdStatus(2));
        changePsdForm.resetFields();
        // setIsModalVisible(true);
      })
      .catch((error) => {
        ErrorNotify(error.message);
      });
  };

  const handleCancel = () => {
    if (setTradePsdStatus !== 1) {
      dispatch(actions.onModalToggle(false));
    } else {
      message.warning("必須設定交易密碼，才能關閉視窗");
      dispatch(actions.onModalToggle(true));
    }
  };

  const handleLogInCancel = () => {
    if (setLogInPsdStatus !== 1) {
      dispatch(actions.onLogInModalToggle(false));
    } else {
      message.warning("必須設定登入密碼，才能關閉視窗");
      dispatch(actions.onLogInModalToggle(true));
    }
  };

  // 關閉公告彈窗
  const handleCancelAnnounce = () => {
    setIsModalVisible(false);
  };
  //申請提現彈窗是否可見
  const showCreateModal = () => {
    if (setTradePsdStatus == 1) {
      dispatch(actions.onModalToggle(true));
    } else if (withdrawTableData.find((item) => item.status == 1)) {
      notification.error({
        message: `仍有待批准的提現訂單, 直到審核過才能再新增`,
      });
    } else {
      setIsCreateModalVisible(true);
    }
  };
  //申請提現
  const handleCreate = (params) => {
    console.log("params", params);
    params.money_password = params.money_password1.concat(
      params.money_password2,
      params.money_password3,
      params.money_password4,
      params.money_password5,
      params.money_password6
    );
    let bodyFormData = new FormData();

    fetchPostWithdraw(params)
      .then((response) => {
        // initializeForm();
        notification.success({ message: "新增成功" });
        setIsCreateModalVisible(false);
        handleFetchList();
      })
      .catch((error) => {
        notification.error({ message: `${error.message}` });
      });
  };

  useEffect(() => {
    if (setLogInPsdStatus == 1) dispatch(actions.onLogInModalToggle(true));
  }, [setLogInPsdStatus]);

  useEffect(() => {
    if (username) {
      handleFetchList();
      handleFetchData();
    }
  }, []);
  const regOrderNo = new RegExp("^\\d{6}$");

  //將字串轉成Html格式
  const showHtml = (item) => {
    let html = { __html: Base64.decode(item.content) };
    return <div dangerouslySetInnerHTML={html}></div>;
  };

  return (
    <OutsideWrapper>
      {/* {username && <div>Hi ! {username}</div>} */}
      <Card>
        <Title orientation="left" level={5}>
          {t("Home.ANNOUNCE_TITLE")}
        </Title>
        {tableData.length > 0 ? (
          <List bordered dataSource={tableData}>
            {tableData.map((item) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <>
                      <Typography.Text mark>
                        [{t("Home.ANNOUNCE_SUBTITLE")}]
                      </Typography.Text>
                      {item.title}
                    </>
                  }
                  description={showHtml(item)}
                />
                {/* <Typography.Text mark>[公告]</Typography.Text> {showHtml(item)} */}
              </List.Item>
            ))}
          </List>
        ) : (
          <p>{t("Home.NON_ANNOUNCE")}</p>
        )}
      </Card>
      <Card style={{ marginTop: ".5rem" }}>
        <Row>
          <Col md={4} xs={6}>
            <Title orientation="left" level={5}>
              {t("Home.PERSONAL_MESSAGE")}
            </Title>
          </Col>
          <Col
            md={{ span: 5, offset: 15 }}
            xs={18}
            style={{ textAlign: "right" }}
          >
            <BlueButton
              style={{ marginRight: "5px" }}
              onClick={() => dispatch(actions.onLogInModalToggle(true))}
            >
              {t("Home.MODIFY_LOGIN_PASSWORD")}
            </BlueButton>
            <RedButton onClick={() => dispatch(actions.onModalToggle(true))}>
              {t("Home.MODIFY_WITHDRAW_PASSWORD")}
            </RedButton>
          </Col>
        </Row>
        <FormWrapper>
          <Row>
            <Col md={4} xs={12}>
              <Form.Item label={t("Home.AGENT_MODE")}>
                {record?.mode == 1 ? "CPA" : record?.mode == 2 ? "CPS" : "CPC"}
              </Form.Item>
            </Col>
            <Col md={4} xs={12}>
              <Form.Item label={t("Home.AGENT_ACCOUNT")}>
                {record?.user_name}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={4} xs={12}>
              {record?.mode == 1 && (
                <Form.Item label={t("Home.ACTIVE_REBATE")}>
                  {record?.cpa_rebate_price}
                  {t("Home.DOLLAR")}
                </Form.Item>
              )}
              {record?.mode == 2 && (
                <Form.Item label={t("Home.ACTIVE_RATE")}>
                  {record?.cps_rebate_rate}%
                </Form.Item>
              )}
              {record?.mode == 3 && (
                <Form.Item label={t("Home.1W_IP")}>
                  {record?.cpc_price}
                  {t("Home.DOLLAR")}
                </Form.Item>
              )}
            </Col>
            <Col md={4} xs={12}>
              <Form.Item label={t("Home.INVITE_CODE")}>
                {record?.invite_code}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={4} xs={12}>
              <Form.Item label={t("WithdrawApply.AGENT_BALANCE")}>
                {record?.balance}
              </Form.Item>
            </Col>
            <Col md={4} xs={12}>
              <Form.Item label={t("WithdrawApply.PREPAY_BALANCE")}>
                {record?.prepay_balance}
              </Form.Item>
            </Col>
          </Row>
          {/* <Form.Item label={"餘額"}>{record?.balance}</Form.Item> */}
        </FormWrapper>
      </Card>
      {windowWidth < 768 ? (
        <>
          <MobileCard>
            <div style={{ display: "flex", algin: "center" }}>
              <StatisticValue
                title={t("Home.BALANCE")}
                value={statisticData.balance}
              ></StatisticValue>
              <RefreshButton type="link" onClick={handleFetchList}>
                <SyncOutlined />
              </RefreshButton>
              <ApplyButton type="link" onClick={showCreateModal}>
                {t("Home.APPLY_WITHDRAW")}
              </ApplyButton>
            </div>
          </MobileCard>
          <MobileCard>
            <Statistic
              title={t("Home.TODAY_MEMBER_COUNT")}
              value={statisticData.today_member_count}
            ></Statistic>
          </MobileCard>
          <MobileCard>
            <Statistic
              title={t("Home.TODAY_COMMISSION")}
              value={statisticData.today_commission}
            ></Statistic>
          </MobileCard>
          <MobileCard>
            <Statistic
              title={t("Home.WEEK_MEMBER_COUNT")}
              value={statisticData.week_member_count}
            ></Statistic>
          </MobileCard>
          <MobileCard>
            <Statistic
              title={t("Home.WEEK_COMMISSION")}
              value={statisticData.week_commission}
            ></Statistic>
          </MobileCard>
        </>
      ) : (
        <Card style={{ marginTop: ".5rem" }}>
          <Title orientation="left" level={5}>
            {t("Home.DATA_OVERVIEW")}
          </Title>
          <Row>
            <Col md={4} xs={24}>
              <Card>
                <div style={{ display: "flex", algin: "center" }}>
                  <StatisticValue
                    title={t("Home.BALANCE")}
                    value={statisticData.balance}
                  ></StatisticValue>
                  <RefreshButton type="link" onClick={handleFetchList}>
                    <SyncOutlined />
                  </RefreshButton>
                  <ApplyButton type="link" onClick={showCreateModal}>
                    {t("Home.APPLY_WITHDRAW")}
                  </ApplyButton>
                </div>
              </Card>
            </Col>
            <Col md={{ span: 4, offset: 1 }} xs={24}>
              <Card>
                <Statistic
                  title={t("Home.TODAY_MEMBER_COUNT")}
                  value={statisticData.today_member_count}
                ></Statistic>
              </Card>
            </Col>
            <Col md={{ span: 4, offset: 1 }} xs={24}>
              <Card>
                <Statistic
                  title={t("Home.TODAY_COMMISSION")}
                  value={statisticData.today_commission}
                ></Statistic>
              </Card>
            </Col>
            <Col md={{ span: 4, offset: 1 }} xs={24}>
              <Card>
                <Statistic
                  title={t("Home.WEEK_MEMBER_COUNT")}
                  value={statisticData.week_member_count}
                ></Statistic>
              </Card>
            </Col>
            <Col md={{ span: 4, offset: 1 }} xs={24}>
              <Card>
                <Statistic
                  title={t("Home.WEEK_COMMISSION")}
                  value={statisticData.week_commission}
                ></Statistic>
              </Card>
            </Col>
          </Row>
        </Card>
      )}

      <ModalWrapper
        visible={isModalVisible}
        onCancel={handleCancelAnnounce}
        footer={null}
        title={t("Home.ANNOUNCE_TITLE")}
      >
        {tableData.length > 0 ? (
          <List bordered dataSource={tableData}>
            {tableData.map((item) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <>
                      <Typography.Text mark>
                        [{t("Home.ANNOUNCE_SUBTITLE")}]
                      </Typography.Text>
                      {item.title}
                    </>
                  }
                  description={showHtml(item)}
                />
                {/* <Typography.Text mark>[公告]</Typography.Text> {showHtml(item)} */}
              </List.Item>
            ))}
          </List>
        ) : (
          <p style={{ textAlign: "center", marginTop: "10px" }}>
            {t("Home.NON_ANNOUNCE")}
          </p>
        )}
      </ModalWrapper>
      <LogInPsdModal
        isVisible={isLogInVisible}
        onCancel={handleLogInCancel}
        form={changePsdForm}
        onFinish={handleFirstLogInSetting}
        setLogInPsdStatus={setLogInPsdStatus}
      />
      <TradePsdModal
        isVisible={isTradeVisible}
        onCancel={handleCancel}
        form={tradeForm}
        onFinish={handleFirstSetting}
        regOrderNo={regOrderNo}
        setTradePsdStatus={setTradePsdStatus}
      />
      <CreateModal
        isVisible={isCreateModalVisible}
        setIsVisible={setIsCreateModalVisible}
        handleCreate={handleCreate}
        statisticValue={statisticData.balance}
        handleFetchList={handleFetchList}
      />
    </OutsideWrapper>
  );
}
