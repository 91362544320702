import Decimal from "decimal.js";
import toFormat from "toformat";
const extendedDecimal = toFormat(Decimal);

extendedDecimal.format = {
  decimalSeparator: ".",
  groupSeparator: ",",
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: "",
  fractionGroupSize: 0,
};

/**
 *
 * @param {string | number | Decimal } value
 * @returns
 */
extendedDecimal.parse = (value, groupSeparator = ",") => {
  // 如果value本身就是Decimal instance, 直接回傳
  if (Decimal.isDecimal(value)) return value;

  if (typeof value === "string") {
    const safetyString = value.replace(new RegExp(groupSeparator, "g"), "");
    return new Decimal(safetyString);
  }

  return new Decimal(value);
};

// export * from 'decimal.js';
export default extendedDecimal;
