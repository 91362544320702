import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { selectInviteCode } from "redux/selector/user";
import { GreenButton, BlueButton, RedButton } from "styles/component";
import {
  SuccessNotify,
  ErrorNotify,
} from "component/Notification/Notification";

import {
  OutsideWrapper,
  Title,
  TableContainer,
  DividerLine,
  ButtonBlock,
  FormWrapper,
} from "./Styled";
import CreateModal from "./component/CreateModal";
import ModifyModal from "./component/ModifyModal";
import { fetchPromoteDomain, fetchCdn } from "api/index";
import { Columns, Columns_BackUp, Columns_Apk } from "./data";
import { CardWrapper } from "./Styled";
import { genderFilterOptions, statusFilterOptions } from "../../options";
import { notification, Tabs, Empty } from "antd";
import { selectMode } from "redux/selector/user";

const PromoLink = () => {
  const { t } = useTranslation();
  const windowWidth = window.innerWidth;
  const height = window.innerHeight;
  const [form] = FormWrapper.useForm();
  const { TabPane } = Tabs;
  // 代理邀請碼
  const inviteCode = useSelector(selectInviteCode);
  // console.log("頁面中邀請碼", inviteCode);
  const [tableData, setTableData] = useState([]);
  const [backUpTableData, setBackUpTableData] = useState([]);
  const [apkTableData, setApkTableData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 1000 });
  const [backUpPagination, setBackUpPagination] = useState({
    current: 1,
    pageSize: 1000,
  });
  const [apkPagination, setApkPagination] = useState({
    current: 1,
    pageSize: 1000,
  });
  const [total, setTotal] = useState(1);
  const [backUpTotal, setBackUpTotal] = useState(1);
  const [apkTotal, setApkTotal] = useState(1);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isModifyModalVisible, setIsModifyModalVisible] = useState(false);
  const [record, setRecord] = useState({});
  // mode: 1 (CPA), 2(CPS), 3(CPC)
  const mode = useSelector(selectMode);
  const handleFetchList = () => {
    let requestBody = form.getFieldsValue();
    mode == 3
      ? fetchPromoteDomain({ ...pagination, ...requestBody, cpc: 1 }).then(
          (res) => {
            // type = 1, 普通推廣域名 , type = 2, 備用推廣域名
            setTableData(res.data.filter((r) => r.type == 1));
            setBackUpTableData(res.data.filter((r) => r.type == 2));
            setTotal(tableData.length);
            setBackUpTotal(backUpTableData.length);
          }
        )
      : fetchPromoteDomain({ ...pagination, ...requestBody, cpc: 2 }).then(
          (res) => {
            // type = 1, 普通推廣域名 , type = 2, 備用推廣域名
            setTableData(res.data.filter((r) => r.type == 1));
            setBackUpTableData(res.data.filter((r) => r.type == 2));
            setTotal(tableData.length);
            setBackUpTotal(backUpTableData.length);
            fetchCdn({ ...pagination, type: 6, status: 1 }).then((res) => {
              setApkTableData(res.data);
              setApkTotal(apkTableData.length);
            });
          }
        );
  };

  const showCreateModal = () => {
    setIsCreateModalVisible(true);
  };

  const showModifyModal = (record) => {
    setIsModifyModalVisible(true);
    setRecord(record);
  };

  const initializeForm = () => {
    setPagination({
      current: 1,
      pageSize: 10,
    });
  };

  // 表格中編輯、刪除、儲存、取消
  const onPageUpdate = (paginate) => {
    setPagination(paginate);
  };
  // (備用連結)
  const onBackUpPageUpdate = (paginate) => {
    setBackUpPagination(paginate);
  };
  // APK直接鏈結
  const onApkPageUpdate = (paginate) => {
    setApkPagination(paginate);
  };

  // Tab 選擇時
  function callback(key) {
    console.log(key);
  }

  const columns = Columns({ t, inviteCode, mode, height, windowWidth });

  const columns_backUp = Columns_BackUp({
    t,
    inviteCode,
    mode,
    height,
    windowWidth,
  });
  const columns_Apk = Columns_Apk({ t, inviteCode, height, windowWidth });

  useEffect(() => {
    handleFetchList();
  }, [pagination]);

  return (
    <OutsideWrapper>
      <CardWrapper>
        <p style={{ color: "#D73A63", marginBottom: 0 }}>
          {t("PromoLink.HINT")}
          <br></br>
          {t("PromoLink.SUB_HINT")}
        </p>
      </CardWrapper>
      {tableData?.length !== 0 && (
        <CardWrapper
          bordered
          // title="普通推廣鏈結"
        >
          <TableContainer
            title={() => (
              <h3 style={{ fontWeight: "bolder" }}>
                {t("PromoLink.CN2_CHANNEL")}
                <span style={{ color: "#D73A63" }}>
                  {t("PromoLink.CN2_INTRODUCTION")}
                </span>
              </h3>
            )}
            showHeader={false}
            // scroll={{ y: "29vw" }}
            columns={columns}
            dataSource={tableData}
            // onChange={onPageUpdate}
            // pagination={{
            //   ...pagination,
            //   total,
            // }}
            pagination={false}
          />
        </CardWrapper>
      )}
      {backUpTableData?.length !== 0 && (
        <CardWrapper
          bordered
          // title="備案推廣鏈結"
        >
          <TableContainer
            title={() => (
              <h3 style={{ fontWeight: "bolder" }}>
                {t("PromoLink.CDN_CHANNEL")}
                <span style={{ color: "#D73A63" }}>
                  {t("PromoLink.CDN_INTRODUCTION")}
                </span>
              </h3>
            )}
            showHeader={false}
            // scroll={{ y: "29vw" }}
            columns={columns_backUp}
            dataSource={backUpTableData}
            // onChange={onBackUpPageUpdate}
            // pagination={{
            //   ...backUpPagination,
            //   backUpTotal,
            // }}
            pagination={false}
          />
        </CardWrapper>
      )}
      {apkTableData?.length !== 0 && mode !== 3 && (
        <CardWrapper
          bordered
          // title="備案推廣鏈結"
        >
          <TableContainer
            title={() => (
              <h3 style={{ fontWeight: "bolder" }}>
                {t("PromoLink.APK_CHANNEL")}
              </h3>
            )}
            showHeader={false}
            // scroll={{ y: "29vw" }}
            columns={columns_Apk}
            dataSource={apkTableData}
            // onChange={onApkPageUpdate}
            // pagination={{
            //   ...apkPagination,
            //   apkTotal,
            // }}
            pagination={false}
          />
        </CardWrapper>
      )}

      {/* <DividerLine orientation="left">
        <Title level={3}>演員管理</Title>
      </DividerLine> */}
      {/* <ButtonBlock>
        <BlueButton onClick={showCreateModal}>新增</BlueButton>
      </ButtonBlock>

      <FormWrapper
        layout={"inline"}
        form={form}
        onFinish={initializeForm}
        autoComplete="off"
        initialValues={{
          gender: 0,
          status: 0,
        }}
      >
        <FormItem label="演員名字(中)" name="name">
          <InputContainer />
        </FormItem>

        <FormItem label="演員名字(原)" name="orig_name">
          <InputContainer />
        </FormItem>

        <FormItem label="性別" name="gender">
          <SelectWrapper>
            {genderFilterOptions.map(({ value, label }, i) => {
              return (
                <Option key={i} value={value}>
                  {label}
                </Option>
              );
            })}
          </SelectWrapper>
        </FormItem>

        <FormItem label="狀態" name="status">
          <SelectWrapper>
            {statusFilterOptions.map(({ value, label }, i) => {
              return (
                <Option key={i} value={value}>
                  {label}
                </Option>
              );
            })}
          </SelectWrapper>
        </FormItem>

        <FormItem>
          <SpaceWrapper>
            <GreenButton>篩選</GreenButton>
            <RedButton
              onClick={() => {
                form.resetFields();
              }}
            >
              重置
            </RedButton>
          </SpaceWrapper>
        </FormItem>
      </FormWrapper> */}

      {/* <CreateModal
        isVisible={isCreateModalVisible}
        setIsVisible={setIsCreateModalVisible}
        handleCreate={handleCreate}
        regionData={regionData}
      />

      <ModifyModal
        isVisible={isModifyModalVisible}
        setIsVisible={setIsModifyModalVisible}
        record={record}
        handleUpdate={handleUpdate}
        setFileListWrapper={setFileListWrapper}
        fileListWrapper={fileListWrapper}
        regionData={regionData}
      /> */}
    </OutsideWrapper>
  );
};

export default PromoLink;
