import DashBoardImg from "assets/icons/dashboard.png";
import PromoLinkImg from "assets/icons/promolink.png";
import StatisticImg from "assets/icons/statistics.png";
import WithdrawImg from "assets/icons/withdraw.png";

const DashBoardIcon = () => {
  return (
    <img
      src={DashBoardImg}
      alt="Home"
      style={{ marginRight: "10px", transform: "scale(0.8)" }}
    />
  );
};

const PromoLinkIcon = () => {
  return (
    <img
      src={PromoLinkImg}
      alt="Data"
      style={{ marginRight: "10px", transform: "scale(0.8)" }}
    />
  );
};

const StatisticIcon = () => {
  return (
    <img
      src={StatisticImg}
      alt="Cms"
      style={{ marginRight: "10px", transform: "scale(0.8)" }}
    />
  );
};

const WithdrawIcon = () => {
  return (
    <img
      src={WithdrawImg}
      alt="Video"
      style={{ marginRight: "10px", transform: "scale(0.8)" }}
    />
  );
};

export const dataSideMenuItems = [
  {
    textKey: "HOME",
    path: "/",
    icon: <DashBoardIcon />,
  },
  {
    textKey: "PROMO_LINK",
    path: "promo-link",
    icon: <PromoLinkIcon />,
  },
  // {
  //   textKey: "MY_PROMOTION",
  //   path: "promotion",
  //   // icon: <CmsIcon />,
  //   children: [
  //     {
  //       textKey: "PROMO_LINK",
  //       path: "promo-link",
  //     },
  //   ],
  // },
  // {
  //   textKey: "AGENT_MANAGER",
  //   path: "agent",
  //   // icon: <CmsIcon />,
  //   children: [
  //     {
  //       textKey: "AGENT_LIST",
  //       path: "agent-list",
  //     },
  //   ],
  // },
  {
    textKey: "INCOME_DETAIL",
    path: "income-detail",
    icon: <StatisticIcon />,
    // children: [
    // {
    //   textKey: "REBATE_DETAIL",
    //   path: "rebate-detail",
    // },
    // {
    //   textKey: "INCOME_DETAIL",
    //   path: "income-detail",
    // },
    // {
    //   textKey: "AGENT_DETAIL",
    //   path: "agent-detail",
    // },
    // ],
  },
  {
    textKey: "WITHDRAW_APPLY",
    path: "withdraw-apply",
    icon: <WithdrawIcon />,
  },
  // {
  //   textKey: "MY_WITHDRAW",
  //   path: "withdraw",
  //   // icon: <MemberIcon />,
  //   children: [
  //     {
  //       textKey: "WITHDRAW_APPLY",
  //       path: "withdraw-apply",
  //     },
  //   ],
  // },
];
