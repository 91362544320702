import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import reducers from "redux/reducer";
import saga from "redux/saga";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const customizedPersistReducer = persistReducer(
  {
    key: "root",
    storage: storage,
  },
  reducers
);

const globalStore = configureStore({
  reducer: customizedPersistReducer,
  middleware: [...middlewares],
  devTools: process.env.NODE_ENV !== "production",
});

sagaMiddleware.run(saga);

export const persistor = persistStore(globalStore);
export default globalStore;
