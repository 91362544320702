import React, { useEffect } from "react";
import { Form, Modal, Input } from "antd";

import {
  BtnCreateAcct,
  Wrapper,
  FormTitle,
  SelectWrapper,
  Option,
} from "./Styled";
import { tabDisplayOptions, tabShowAdOptions, statusOptions } from "options";

const ResetPassword = ({
  record,
  isVisible,
  setIsVisible,
  navOptions,
  handleUpdate,
}) => {
  const [form] = Form.useForm();

  // useEffect(() => {
  //   form.FieldsValue({
  //     id: record.id,
  //     nav_id: record.nav_id,
  //     name: record.name,
  //     desplay_type: record.desplay_type,
  //     show_ad: record.show_ad,
  //     status: record.status,
  //     seqno: record.seqno,
  //   });
  // }, [record]);

  return (
    <Modal
      width={450}
      centered={true}
      maskClosable={false}
      footer={null}
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
    >
      <Wrapper>
        <Form
          form={form}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          autoComplete="off"
          initialValues={{}}
          onFinish={handleUpdate}
        >
          <FormTitle>重置密碼</FormTitle>

          <Form.Item label={"新密碼"} name="password">
            <Input autoComplete="new-password" />
          </Form.Item>
          <Form.Item label={"確認新密碼"} name="confirm_password">
            <Input autoComplete="new-password" />
          </Form.Item>

          <BtnCreateAcct htmlType="submit">更新</BtnCreateAcct>
        </Form>
      </Wrapper>
    </Modal>
  );
};

export default ResetPassword;
