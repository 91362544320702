import styled from "styled-components";
import {
  Form,
  Typography,
  Table,
  Input,
  Select,
  Space,
  DatePicker,
  Divider,
} from "antd";
import "App.css";

export const OutsideWrapper = styled.div`
  padding: var(--pages-padding-top);

  .ant-table {
    color: hsla(0, 0%, 100%, 0.7);
  }
`;

export const ButtonBlock = styled.div`
  text-align: right;
  padding-bottom: 16px;
`;

export const Title = styled(Typography.Title)`
  color: #fff !important;
`;
export const TableContainer = styled(Table)`
  .ant-table-thead {
    padding: 16px !important;
  }
`;
export const FormWrapper = styled(Form)`
  padding: 16px;
`;
export const FormItem = styled(Form.Item)`
  padding: 16px 0;
  margin-right: 0;
`;

export const DividerLine = styled(Divider)`
  margin-bottom: 0 !important;
`;
export const DatePickerWrapper = styled(DatePicker)``;
export const InputContainer = styled(Input)``;
export const SelectWrapper = styled(Select)`
  background: red !important;
  .ant-select-selector {
    width: 100% !important;
  }
`;
export const Option = styled(Select.Option)``;
export const SpaceWrapper = styled(Space)``;
export const FormText = styled.span`
  color: #fff;
  font-size: 16px;
`;
