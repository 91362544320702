import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Avatar, Row, Col, Button } from "antd";
import PersonalModal from "./component/PersonalModal";
import { Trans, useTranslation } from "react-i18next";
import actions from "redux/action";
import { useSelector, useDispatch } from "react-redux";
import { selectAppName } from "redux/selector/app";
import {
  selectUserName,
  selectRecord,
  selectMode,
  selectInviteCode,
} from "redux/selector/user";
import { fetchMe, fetchPutPassword, fetchPutTradePassword } from "api";
import {
  DownOutlined,
  LogoutOutlined,
  UserOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import {
  SuccessNotify,
  ErrorNotify,
} from "component/Notification/Notification";
import {
  HeaderWrapper,
  Wrapper,
  SystemName,
  Info,
  MenuWrapper,
  DropDownWrapper,
  MenuItem,
} from "./Styled";

export default function Navbar({
  width,
  dataSideMenuItems,
  showDrawer,
  handleSwitchSideMenu,
}) {
  // const [record, setRecord] = useState({});
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  const mode = useSelector(selectMode);
  // const record = useSelector(selectRecord);
  const navigate = useNavigate();
  const appName = useSelector(selectAppName);
  const username = useSelector(selectUserName);
  const inviteCode = useSelector(selectInviteCode);
  const record = useSelector(selectRecord);
  const dispatch = useDispatch();

  const handleLogout = () => {
    navigate("login");
  };

  const onModalOpen = () => {
    setIsVisible(true);
    fetchMe().then((res) => {
      dispatch(actions.updateMode(res.mode));
      dispatch(actions.updateRecord(res));
      dispatch(actions.storeInviteCode(res.invite_code));
    });
    // dispatch(actions.onModalToggle(true));
  };

  const handleChangePsd = (params) => {
    let old_password = params.old_password;
    let new_password = params.new_password;
    fetchPutPassword({ old_password, new_password })
      .then((res) => {
        SuccessNotify("修改成功");
        setIsVisible(false);
        // dispatch(actions.onModalToggle(false));
      })
      .catch((error) => {
        ErrorNotify(error.message);
      });
  };

  const handleChangeTradePsd = (params) => {
    let old_password = params.old_password;
    let new_password = params.new_password;
    fetchPutTradePassword({ old_password, new_password })
      .then((res) => {
        SuccessNotify("修改成功");
        setIsVisible(false);
        // dispatch(actions.onModalToggle(false));
        // dispatch(actions.tradPsdStatus(2));
      })
      .catch((error) => {
        ErrorNotify(error.message);
      });
  };

  // useEffect(() => {
  //   fetchMe().then((res) => setRecord(res));
  // }, [mode]);

  const WebHeaderWrapper = () => (
    <Wrapper>
      <SystemName></SystemName>
      <Info>
        <div>
          <span style={{ fontSize: "16px" }}>
            {t("NavBar.AGENT_MODE")}:{" "}
            {mode == 1 ? "CPA" : mode == 2 ? "CPS" : "CPC"}
          </span>
        </div>

        {mode == 1 && (
          <div style={{ marginRight: "10px" }}>
            <span style={{ fontSize: "16px" }}>
              {t("NavBar.ACTIVE_REBATE")}: {record?.cpa_rebate_price}元
            </span>
          </div>
        )}
        {mode == 2 && (
          <div style={{ marginRight: "10px" }}>
            <span style={{ fontSize: "16px" }}>
              {t("NavBar.ACTIVE_RATE")}: {record?.cps_rebate_rate}%
            </span>
          </div>
        )}
        {mode == 3 && (
          <div style={{ marginRight: "10px" }}>
            <span style={{ fontSize: "16px" }}>
              {t("NavBar.1W_IP")}: {record?.cpc_price}元
            </span>
          </div>
        )}
        <div>
          <span>
            <Avatar src={`https://api.multiavatar.com/${new Date()}.png`} />
          </span>
        </div>
        <div>
          <span style={{ fontSize: "16px" }}>
            <DropDownWrapper overlay={menu} trigger={["click"]}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                {username}
                <DownOutlined />
              </a>
            </DropDownWrapper>
          </span>
        </div>
      </Info>
    </Wrapper>
  );

  const MobileHeaderWrapper = () => {
    return dataSideMenuItems.map((item) => {
      // console.log("標題", item.textKey);
      console.log("/${item.path}", item.path);
      console.log("pathname", pathname);
      if (`${item.path}` == "/" && `${item.path}` == pathname) {
        return (
          <Row style={{ background: "#fff" }}>
            {/* <Col span={4} style={{ textAlign: "center" }}>
              <Button
                type="link"
                icon={<MenuOutlined />}
                onClick={showDrawer}
                style={{ color: "#000" }}
              ></Button>
            </Col> */}
            <Col span={24} style={{ textAlign: "center" }}>
              <Button
                type="link"
                icon={<MenuOutlined />}
                onClick={showDrawer}
                style={{
                  color: "#000",
                  position: "absolute",
                  left: "26px",
                  top: "16px",
                }}
              ></Button>
              {t(`SideMenuItems.${item.textKey}`)}
            </Col>
          </Row>
        );
      } else if (`${item.path}` !== "/" && `/${item.path}` == pathname) {
        return (
          <Row style={{ background: "#fff" }}>
            {/* <Col span={4} style={{ textAlign: "center" }}>
              <Button
                type="link"
                icon={<MenuOutlined />}
                onClick={showDrawer}
                style={{ color: "#000" }}
              ></Button>
            </Col> */}
            <Col span={24} style={{ textAlign: "center" }}>
              <Button
                type="link"
                icon={<MenuOutlined />}
                onClick={showDrawer}
                style={{
                  color: "#000",
                  position: "absolute",
                  left: "26px",
                  top: "16px",
                }}
              ></Button>
              {t(`SideMenuItems.${item.textKey}`)}
            </Col>
          </Row>
        );
      }
    });
  };

  const menu = (
    <MenuWrapper>
      {/* <MenuItem key="0">
        <a onClick={onModalOpen}>
          <UserOutlined /> 個人信息
        </a>
      </MenuItem> */}
      <MenuItem key="0">
        <a onClick={handleSwitchSideMenu}>
          <LogoutOutlined /> {t("NavBar.LOGOUT")}
        </a>
      </MenuItem>
    </MenuWrapper>
  );

  if (!username) return <></>;

  return (
    <HeaderWrapper>
      {width > 768 ? <WebHeaderWrapper /> : <MobileHeaderWrapper />}
      <PersonalModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        record={record}
        handleChangePsd={handleChangePsd}
        handleChangeTradePsd={handleChangeTradePsd}
      />
    </HeaderWrapper>
  );
}
