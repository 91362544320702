import styled from "styled-components";
import { Drawer } from "antd";

export const DrawerWrapper = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
    > div {
      height: 100%;
    }
  }
`;
