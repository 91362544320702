export const selectUser = (state) => state.user;
export const selectUserName = (state) => state.user.name;
export const selectMode = (state) => state.user.mode;
export const selectRecord = (state) => state.user.record;
export const selectInviteCode = (state) => state.user.invite_code;
export const selectIsTradeVisible = (state) => state.user.isVisible;
export const selectIsLogInVisible = (state) => state.user.isLogInVisible;
export const selectSetTradePsd = (state) => state.user.isSetTradPsd;
export const selectSetLogInPsd = (state) => state.user.isSetPsd;
export const selectOldPsd = (state) => state.user.oldPsd;
