import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useTranslation } from "react-i18next";
import empty from "assets/icons/empty.png";
import { GreenButton, BlueButton, RedButton } from "styles/component";
import {
  SuccessNotify,
  ErrorNotify,
} from "component/Notification/Notification";

import {
  OutsideWrapper,
  Title,
  TableContainer,
  DividerLine,
  ButtonBlock,
  FormWrapper,
  RangePicker,
} from "./Styled";
import CreateModal from "./component/CreateModal";
import ModifyModal from "./component/ModifyModal";
import {
  fetchCpaDaily,
  fetchCpsDaily,
  fetchCpcDaily,
  // fetchActor,
  // fetchDeleteActor,
  // fetchVideoRegion,
} from "api/index";
import { Columns, Columns_CPA, Columns_CPC } from "./data";
import {
  FormItem,
  InputContainer,
  Option,
  SelectWrapper,
  SpaceWrapper,
  CardWrapper,
} from "./Styled";
import { genderFilterOptions, statusFilterOptions } from "../../options";
import { notification, Tabs, Empty, ConfigProvider } from "antd";
import { useSelector } from "react-redux";
import { selectMode } from "redux/selector/user";

const IncomeDetail = () => {
  const { t } = useTranslation();
  const windowWidth = window.innerWidth;
  // mode: 1 (CPA), 2(CPS)
  const mode = useSelector(selectMode);
  const [form] = FormWrapper.useForm();
  const { TabPane } = Tabs;
  const [tableData, setTableData] = useState([]);
  // 上方篩選的起始時間與結束時間

  // 設置今天
  const today = new Date();
  // 設置七天前
  const sevenDays = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 7
  );
  const [rangeDate, setRangeDate] = useState([sevenDays, today]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [total, setTotal] = useState(1);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isModifyModalVisible, setIsModifyModalVisible] = useState(false);
  const [record, setRecord] = useState({});

  const transDate = (text) =>
    `${text.getFullYear()}-${text.getMonth() + 1}-${text.getDate()}`;

  // 空狀態顯示
  const customizeRenderEmpty = () => {
    return (
      <>
        <Empty
          image={empty}
          imageStyle={{
            height: 60,
          }}
          description={t("IncomeDetail.NO_DATA")}
        ></Empty>
      </>
    );
  };

  const handleFetchList = () => {
    let requestBody = form.getFieldsValue();
    // 如果 rangeDate 還沒設定，就走這裡
    // if (rangeDate.length == 0) {
    //   requestBody.date_begin = transDate(sevenDays);
    //   requestBody.date_end = transDate(today);
    // } else {
    // 如果選擇了日期就走這裡
    requestBody.date_begin = moment(rangeDate[0])?.format(
      "yyyy-MM-DD 00:00:00"
    );
    requestBody.date_end = moment(rangeDate[1])?.format("yyyy-MM-DD 23:59:59");
    // }
    mode == 1
      ? fetchCpaDaily({ ...requestBody }).then((res) => {
          setTableData(res.data);
          setTotal(res.data.page_result.total);
        })
      : mode == 2
      ? fetchCpsDaily({ ...requestBody }).then((res) => {
          setTableData(res.data);
          setTotal(res.data.page_result.total);
        })
      : fetchCpcDaily({ ...requestBody, current: 1, pageSize: 10 }).then(
          (res) => {
            setTableData(res.data);
            setTotal(res.data.page_result.total);
          }
        );
  };

  const showCreateModal = () => {
    setIsCreateModalVisible(true);
  };

  const showModifyModal = (record) => {
    setIsModifyModalVisible(true);
    setRecord(record);
  };

  const initializeForm = () => {
    setPagination({
      current: 1,
      pageSize: 10,
    });
  };

  // 表格中編輯、刪除、儲存、取消
  const onPageUpdate = (paginate) => {
    setPagination(paginate);
  };

  // Tab 選擇時
  const callback = (key) => {
    console.log(key);
  };

  // 日期變更時
  const onDateChange = (_, dateString) => {
    setRangeDate(dateString);
  };

  // 日期選擇限制
  const disabledDate = (current) =>
    // current > moment() || current < moment().subtract(7, "days");
    {
      if (!rangeDate || rangeDate.length === 0) {
        return false;
      }
      // 不可选择的日期
      const tooLate = rangeDate[0] && current.diff(rangeDate[0], "days") > 7;
      const tooEarly =
        rangeDate[1] && moment(rangeDate[0]).diff(current, "days") >= 7;
      return tooEarly || tooLate;
    };

  const columns = Columns({
    // handleDelete,
    // showModifyModal,
    // handleUpdate,
    // regionData,
  });

  const columns_cpa = Columns_CPA({
    // handleDelete,
    // showModifyModal,
    // handleUpdate,
    // regionData,
  });
  const columns_cpc = Columns_CPC({});

  useEffect(() => {
    handleFetchList();
  }, [pagination]);

  return (
    <OutsideWrapper>
      <CardWrapper>
        <FormWrapper
          layout={windowWidth < 768 ? "inline" : ""}
          form={form}
          onFinish={initializeForm}
          autoComplete="off"
          initialValues={{}}
        >
          <div
            style={{
              display: windowWidth < 768 ? "block" : "flex",
              justifyContent: "space-between",
            }}
          >
            <FormItem label={t("IncomeDetail.DATE")} name="date">
              <RangePicker
                // showTime
                onCalendarChange={onDateChange}
                disabledDate={disabledDate}
                defaultValue={[moment(sevenDays), moment(today)]}
              ></RangePicker>
            </FormItem>

            <FormItem style={{ textAlign: "right" }}>
              <SpaceWrapper>
                <BlueButton>{t("IncomeDetail.FILTER")}</BlueButton>
                <RedButton
                  onClick={() => {
                    form.resetFields();
                    setRangeDate([sevenDays, today]);
                  }}
                >
                  {t("IncomeDetail.RESET")}
                </RedButton>
              </SpaceWrapper>
            </FormItem>
          </div>
        </FormWrapper>
      </CardWrapper>
      <CardWrapper bordered>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <TableContainer
            scroll={{ x: "769px" }}
            columns={
              mode == 1 ? columns_cpa : mode == 2 ? columns : columns_cpc
            }
            dataSource={tableData}
            onChange={onPageUpdate}
            format="YYYY-MM-DD"
            pagination={{
              ...pagination,
              total,
            }}
          />
        </ConfigProvider>
      </CardWrapper>
    </OutsideWrapper>
  );
};

export default IncomeDetail;
