import styled from "styled-components";
import { BlueButton } from "styles/component";
import { Button, Form, Input, Select, Space, Table, Divider } from "antd";

export const Wrapper = styled.div``;

export const BtnCreateAcct = styled(BlueButton)`
  margin-top: 60px;
  width: 100%;
  height: 40px;
  color: #fff;
`;

export const FormTitle = styled.h2`
  font-weight: 300;
  color: #fff;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #3f3f3f;
`;

export const TableContainer = styled(Table)``;
export const FormWrapper = styled(Form)``;
export const FormItem = styled(Form.Item)`
  padding: 0.102rem 0.146rem 0.102rem 0;
  margin-right: 0 !important;
`;
export const InputContainer = styled(Input)``;
export const SelectWrapper = styled(Select)`
  .ant-select-selector {
    width: 100% !important;
  }
`;
export const Option = styled(Select.Option)``;
export const SpaceWrapper = styled(Space)``;
export const ButtonWrapper = styled(Button)``;
export const DividerWrapper = styled(Divider)`
  color: #fff !important;
`;
export const pWrapper = styled.p`
  color: #fff !important;
`;
