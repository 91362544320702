import { put, take, takeLatest, call } from "redux-saga/effects";
import actions from "redux/action";

function* init() {
  yield console.log("App init");
}

function* getAppName() {
  yield console.log("getAppName");
}

export default function* watchApp() {
  yield call(init);
  yield takeLatest(actions.initApp, getAppName);
}
