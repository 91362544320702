import styled from "styled-components";
import { Form, Modal, Input, Button } from "antd";
import "App.css";
import confirm from "assets/icons/confirm.png";
import key from "assets/icons/log-in-key.png";

export const FormWrapper = styled(Form)`
  margin-top: 24px;
`;
export const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    border-radius: var(--modal-content-border-radius);
  }
  .ant-modal-title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #5a5a5a;
  }
  .ant-modal-header {
    text-align: center;
    padding: 24px 0 10px 0;
    border-radius: var(--modal-header-border-radius);
  }
  .ant-modal-body {
    padding: 0 86px 24px 86px;
  }
`;
export const PasswordIcon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${key});
  background-size: contain;
`;

export const ConfirmIcon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${confirm});
  background-size: contain;
`;

export const InputPasswordWrapper = styled(Input.Password)`
  border-radius: var(--input-border-radius);
  background: var(--input-bg-color);
  &.ant-input-affix-wrapper-status-error,
  input {
    background: var(--input-bg-color) !important;
  }
`;

export const ConfirmBtn = styled(Button)`
  border-radius: 4px;
  width: 100%;
  padding: 10.5px 0;
  height: auto;
`;
