import { Button, Space, Popconfirm, Image, Switch, Empty } from "antd";
import keyBy from "lodash/keyBy";
import { areaOptions, genderOptions } from "options";
import Decimal from "lib/decimal";
import { timeFormat } from "functions/timeFormat";
import { t } from "i18next";

const objectArea = keyBy(areaOptions, (e) => e.value);
const objectGender = keyBy(genderOptions, (e) => e.value);
const onlyDate = (text) => {
  if (text && text !== null && text.includes("T")) {
    return text.split("T")[0];
  } else {
    return text;
  }
};

// 下方表格 CPs
export const Columns = ({
  handleDelete,
  showModifyModal,
  handleUpdate,
  regionData,
}) => [
  {
    title: t("IncomeDetail.DATE"),
    dataIndex: "date",
    key: "date",
    render: (text) => onlyDate(text),
  },
  {
    title: t("IncomeDetail.CUSTOMER_SUB_MEMBER_COUNT"),
    dataIndex: "customer_sub_member_count",
    key: "customer_sub_member_count",
  },
  {
    title: t("IncomeDetail.SUB_MEMBER_TOTAL_CPA_REBATE"),
    dataIndex: "customer_sub_member_total_recharge",
    key: "customer_sub_member_total_recharge",
  },
  {
    title: "ARPU",
    dataIndex: "customer_sub_member_total_recharge",
    key: "customer_sub_member_total_recharge",
    render: (text, record) => {
      if (
        record?.customer_sub_member_total_recharge !== 0 &&
        record?.customer_sub_member_count !== 0
      ) {
        return `${Decimal(
          record.customer_sub_member_total_recharge /
            record.customer_sub_member_count
        )?.toFormat(2)}`;
      } else {
        return `${Decimal(0)?.toFormat(2)}`;
      }
    },
  },
  {
    title: t("IncomeDetail.SUB_MEMBER_TOTAL_COMMISSION"),
    dataIndex: "sub_member_total_commission",
    key: "sub_member_total_commission",
  },
  {
    title: t("IncomeDetail.REGION_ID_CPS"),
    dataIndex: "region_id",
    key: "region_id",
    render: (_, record) =>
      record.total_commission - record.sub_member_total_commission,
  },
  {
    title: t("IncomeDetail.TOTAL_COMMISSION_CPS"),
    dataIndex: "total_commission",
    key: "total_commission",
  },
];

// 下方表格 CPA
export const Columns_CPA = ({
  handleDelete,
  showModifyModal,
  handleUpdate,
  regionData,
}) => [
  {
    title: t("IncomeDetail.DATE"),
    dataIndex: "date",
    key: "date",
    render: (text) => onlyDate(text),
  },
  {
    title: t("IncomeDetail.CUSTOMER_SUB_MEMBER_COUNT"),
    dataIndex: "customer_sub_member_count",
    key: "customer_sub_member_count",
  },
  {
    title: t("IncomeDetail.SUB_MEMBER_TOTAL_CPA_REBATE"),
    dataIndex: "sub_member_total_cpa_rebate",
    key: "sub_member_total_cpa_rebate",
  },
  {
    title: t("IncomeDetail.REGION_ID"),
    dataIndex: "region_id",
    key: "region_id",
    render: (_, record) =>
      record.total_cpa_rebate - record.sub_member_total_cpa_rebate,
  },
  {
    title: t("IncomeDetail.TOTAL_CPA_REBATE"),
    dataIndex: "total_cpa_rebate",
    key: "total_cpa_rebate",
  },
];

// 下方表格 CPC
export const Columns_CPC = ({
  handleDelete,
  showModifyModal,
  handleUpdate,
  regionData,
}) => [
  {
    title: t("IncomeDetail.STATISTIC_DATE"),
    dataIndex: "date",
    key: "date",
    render: (text) => onlyDate(text),
  },
  {
    title: t("IncomeDetail.SETTLEMENT_LANDING_PAGE_COUNT"),
    dataIndex: "settlement_landing_page_count",
    key: "settlement_landing_page_count",
  },
  {
    title: t("IncomeDetail.CPC_PRICE"),
    dataIndex: "cpc_price",
    key: "cpc_price",
  },
  {
    title: t("IncomeDetail.TOTAL_COMMISSION"),
    dataIndex: "total_commission",
    key: "total_commission",
    // render: (_, record) =>
    //   record.total_cpa_rebate - record.sub_member_total_cpa_rebate,
  },
  {
    title: t("IncomeDetail.CREATED_AT"),
    dataIndex: "created_at",
    key: "created_at",
    render: (text) => timeFormat(text),
  },
];
