export const timeFormat = (text) => {
  if (text && text !== null && text.includes("T")) {
    return text
      .split("T")[0]
      .concat("  ")
      .concat(text.split("T")[1].split(".")[0].split("+")[0]);
  } else {
    return text;
  }
};
