import styled from "styled-components";
import { Button } from "../css";

export const GreenButton = styled.button`
  ${Button};

  background: linear-gradient(to top, #5835b8 50%, #6e44dc);
  background-size: 210% 210%;
`;

export const RedButton = styled.button`
  ${Button};

  background: #d73a63;
  background-size: 210% 210%;
  &:hover {
    background: #b61f47;
  }
`;

export const BlueButton = styled.button`
  ${Button};

  background: #448ef7;
  &:hover {
    box-shadow: 2px 2px 6px rgb(51 88 244 / 40%);
    background: #367de0;
  }
`;

export const DarkButton = styled.button`
  ${Button};

  background: linear-gradient(310deg, #141727 0%, #3a416f 100%);
  &:hover {
    box-shadow: 2px 2px 6px rgb(0 0 0 / 40%);
  }
`;
