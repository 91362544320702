import styled from "styled-components";
import { Layout } from "antd";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
const { Header } = Layout;

export const HeaderWrapper = styled(Header)`
  background-color: #d9d9d9;
`;

export const Wrapper = styled(Header)`
  display: flex;
  justify-content: space-between;
  background-color: inherit;
  color: #000;
  padding-right: 16px;
`;

export const SystemName = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

export const Info = styled.div`
  display: flex;
  gap: 10px;
`;

export const MenuWrapper = styled(Menu)``;

export const DropDownWrapper = styled(Dropdown)``;
export const MenuItem = styled(Menu.Item)``;
