import styled from "styled-components";
import { Typography, Table, Divider, Form, Select, Space, Input } from "antd";
import "App.css";
export const OutsideWrapper = styled.div`
  padding: var(--pages-padding-top);
  ${"" /* max-width: 1366px; */}
`;
export const Title = styled(Typography.Title)`
  color: #fff !important;
`;
export const FormWrapper = styled(Form)`
  padding: 16px;
`;
export const TableContainer = styled(Table)``;
export const DividerLine = styled(Divider)`
  margin-bottom: 0 !important;
`;
export const ButtonBlock = styled.div`
  text-align: right;
  padding-bottom: 16px;
`;

export const FormItem = styled(Form.Item)`
  padding: 16px 0;
  margin-right: 0;
`;
export const InputContainer = styled(Input)``;
export const SelectWrapper = styled(Select)`
  .ant-select-selector {
    width: 95px !important;
  }
`;
export const Option = styled(Select.Option)``;
export const SpaceWrapper = styled(Space)``;
