/** Filter */
export const statusFilterOptions = [
  { value: 0, label: "全部" },
  { value: 1, label: "開啟" },
  { value: 2, label: "關閉 " },
];
export const userPermissionFilterOptions = [
  { value: 0, label: "全部" },
  { value: 1, label: "管理者" },
  { value: 2, label: "一般用戶" },
];
export const genderFilterOptions = [
  { value: 0, label: "全部" },
  { value: 1, label: "男" },
  { value: 2, label: "女" },
];
export const levelFilterOptions = [
  { value: 0, label: "全部" },
  { value: 1, label: "一級" },
  { value: 2, label: "二級" },
];

/** Nav */
export const navIconOptions = [
  { value: 1, label: "首頁", icon: "home" },
  { value: 2, label: "熱播", icon: "hot" },
  { value: 3, label: "我的", icon: "mine" },
];

/** Tab */
export const statusOptions = [
  { value: 1, label: "開啟" },
  { value: 2, label: "關閉 " },
];

export const tabDisplayOptions = [{ value: 1, label: "基礎模式" }];

export const tabShowAdOptions = [
  { value: 1, label: "顯示" },
  { value: 2, label: "不顯示" },
];
/** Tab - 金剛區模板 */
export const iconuiOptions = [
  { value: 1, label: "三個居中" },
  { value: 2, label: "四個居中" },
  { value: 3, label: "三個半" },
  { value: 4, label: "四個半" },
  { value: 5, label: "四個換行" },
];

/** AdminUser */
export const userStatusOptions = [
  { value: 1, label: "啟用" },
  { value: 2, label: "停用 " },
];

export const userPermissionOptions = [
  { value: 1, label: "管理者" },
  { value: 2, label: "一般用戶" },
];

/** Area */
export const templateOptions = [
  { value: 1, label: "小圖" },
  { value: 2, label: "大圖" },
  { value: 3, label: "一大多小" },
  { value: 4, label: "左右滑動(大)" },
  { value: 5, label: "左右滑動(小)" },
];

export const regionOptions = [
  { value: 0, label: "無" },
  { value: 1, label: "國產" },
  { value: 2, label: "動漫" },
  { value: 3, label: "綜藝" },
  { value: 4, label: "歐美" },
  { value: 5, label: "日韓" },
  { value: 6, label: "亞洲" },
];

export const sortByOptions = [
  { value: "created_at", label: "最新" },
  { value: "view_count", label: "最熱" },
  { value: "video_code", label: "番號(升)" },
  { value: "-video_code", label: "番號(降)" },
  { value: "random", label: "隨機排序" },
  { value: "vip_first", label: "VIP優先" },
];

/** Actor */
export const genderOptions = [
  { value: 1, label: "男" },
  { value: 2, label: "女" },
];
export const areaOptions = [
  { value: 0, label: "無" },
  { value: 1, label: "亞洲" },
  { value: 2, label: "日韓" },
  { value: 3, label: "歐美" },
  { value: 4, label: "國產" },
];

/** Tag */
export const levelOptions = [
  { value: 1, label: "一級" },
  { value: 2, label: "二級" },
];

/** Video */
export const paymentTypeOptions = [
  { value: 1, label: "免費" },
  { value: 2, label: "金幣" },
  { value: 3, label: "VIP" },
];

export const shelfTypeOptions = [
  { value: 1, label: "未上架" },
  { value: 2, label: "已上架" },
  { value: 3, label: "已下架" },
];

/** Publish */
export const publishStatusOptions = [
  { value: 1, label: "待發佈" },
  { value: 2, label: "已發佈" },
];

/** Agent */
export const tradeTypeOptions = [
  { value: 1, label: "返傭" },
  { value: 2, label: "提現扣款" },
  { value: 3, label: "新增預付" },
];

export const agentModeOptions = [
  { value: 1, label: "CPA" },
  { value: 2, label: "CPS" },
];

/** Device */
export const vipOptions = [
  { value: 1, label: "VIP" },
  { value: 2, label: "非VIP" },
];

/** 會員管理 - 更多詳情 */
export const modalOptions = [
  { value: 1, label: "設置VIP" },
  { value: 2, label: "添加金幣" },
  { value: 3, label: "綁定代理" },
];

export const memberTypeOptions = [
  { value: 1, label: "遊客" },
  { value: 2, label: "會員" },
];
export const deviceOptions = [
  { value: "IDFA", label: "ios" },
  { value: "GPS ADID", label: "android" },
  { value: "cookie", label: "h5" },
];

/** 商品管理 */
export const subProductTypeOptions = [
  { value: 1, label: "VIP" },
  { value: 2, label: "金幣" },
  { value: 3, label: "金幣觀看" },
];

/** 推廣域名 - 類別 */
export const domainTypeOptions = [
  { value: 1, label: "常用" },
  { value: 2, label: "QQ" },
  { value: 3, label: "微信" },
];

/** 金剛區 */
export const jumpTypeOptions = [
  { value: 1, label: "片商" },
  { value: 2, label: "演員" },
  { value: 3, label: "片商列表" },
  { value: 4, label: "演員列表" },
  { value: 5, label: "開通VIP" },
  { value: 6, label: "鏈接" },
];

/** 支付平台 */
export const keyTypeOptions = [
  { value: 1, label: "私鑰" },
  { value: 2, label: "公鑰" },
];

/** 訂單明細 */
export const rebateStateOptions = [
  { value: 1, label: "未返佣" },
  { value: 2, label: "已返佣" },
];

export const payStateOptions = [
  { value: 1, label: "待付款" },
  { value: 2, label: "已付款" },
  { value: 3, label: "已取消" },
];

/** 代理提現 */
export const withdrawStateOptions = [
  { value: 1, label: "待批准" },
  { value: 2, label: "通過" },
  { value: 3, label: "拒絕" },
  { value: 4, label: "取消" },
];
