import { Button, Space, Popconfirm } from "antd";
import { Select } from "antd";
import keyBy from "lodash/keyBy";
import { statusOptions, tradeTypeOptions } from "options";

const objectStatus = keyBy(statusOptions, (e) => e.value);
const { Option } = Select;
// 下方表格
export const Columns = () => [
  {
    title: "日期",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "類型",
    dataIndex: "status",
    key: "status",
    render: (text) =>
      tradeTypeOptions.map((item) => {
        if (item.value == text) return item.label;
      }),
  },
  {
    title: "金額",
    dataIndex: "rebate_amount",
    key: "rebate_amount",
  },
  {
    title: "變動前餘額",
    dataIndex: "balance_old",
    key: "balance_old",
  },
  {
    title: "變動後餘額",
    dataIndex: "balance_new",
    key: "balance_new",
  },
  {
    title: "變動前預付",
    dataIndex: "prepay_old",
    key: "prepay_old",
  },
  {
    title: "變動後預付",
    dataIndex: "prepay_new",
    key: "prepay_new",
  },
];
