import { Button, Space, Popconfirm, Image, Switch, Empty } from "antd";
import keyBy from "lodash/keyBy";
import { areaOptions, genderOptions } from "options";
import EditIcon from "assets/icons/edit.png";
import DeleteIcon from "assets/icons/delete.png";

const objectArea = keyBy(areaOptions, (e) => e.value);
const objectGender = keyBy(genderOptions, (e) => e.value);

// 下方表格
export const Columns = ({
  handleDelete,
  showModifyModal,
  handleUpdate,
  regionData,
}) => [
  {
    title: "頭像",
    dataIndex: "avatar",
    key: "avatar",
    width: 100,
    render: (text, record, i) => {
      if (text !== null) {
        return (
          <Image
            width={{ width: "80%" }}
            src={`data:image/jpeg;base64,${text}`}
          />
        );
      } else {
        return "-";
      }
    },
  },
  {
    title: "演員名字（中）",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "演員名字（原）",
    dataIndex: "orig_name",
    key: "orig_name",
  },
  {
    title: "性別",
    dataIndex: "gender",
    key: "gender",
    render: (_) => {
      return objectGender[_].label;
    },
  },
  {
    title: "地區",
    dataIndex: "region_id",
    key: "region_id",
    render: (text) => {
      const objRegion = keyBy(regionData, (e) => e.id);
      return objRegion[`${text}`].name;
    },
  },
  {
    title: "視頻數量",
    dataIndex: "video_amount",
    key: "video_amount",
  },
  {
    title: "狀態",
    dataIndex: "status",
    key: "status",
    render: (_, record) => {
      return (
        <Switch
          checkedChildren="開啟"
          unCheckedChildren="關閉"
          checked={!(_ >> 1)}
          onChange={(isCheck) =>
            handleUpdate({ ...record, status: isCheck ? 1 : 2 })
          }
        />
      );
    },
  },
  {
    title: "操作",
    dataIndex: "actions",
    key: "actions",
    render: (_, record) => {
      if (record) {
        return (
          <Space size={"middle"}>
            {/* <Button onClick={() => props.handleShutDown}>關閉</Button> */}
            <img
              src={EditIcon}
              alt="edit"
              onClick={() => showModifyModal(record)}
            />
            <Popconfirm
              title="請確認是否刪除？"
              okText={"確認"}
              cancelText={"取消"}
              onConfirm={() => handleDelete(record.id)}
            >
              <img src={DeleteIcon} alt="delete" />
            </Popconfirm>
          </Space>
        );
      }
    },
  },
];
