import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Tabs, Radio, InputNumber, Divider } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { areaOptions, genderOptions, statusOptions } from "options";

import {
  SuccessNotify,
  ErrorNotify,
} from "component/Notification/Notification";
import {
  FormWrapper,
  BtnCreateAcct,
  Wrapper,
  FormTitle,
  SelectWrapper,
  ModalWrapper,
  Value,
  RefreshButton,
  InputWrapper,
  InputNumberWrapper,
  FillBtn,
  InputVerifyWrapper,
  AnotherCol,
  FirstCol,
} from "./Styled";

const CreateModal = ({
  isVisible,
  setIsVisible,
  handleCreate,
  statisticValue,
  handleFetchList,
}) => {
  const { t } = useTranslation();
  const firstRef = useRef(null);
  const secondRef = useRef(null);
  const thirdRef = useRef(null);
  const fourthRef = useRef(null);
  const fifthRef = useRef(null);
  const sixthRef = useRef(null);
  const [form] = FormWrapper.useForm();
  const { TabPane } = Tabs;
  const [radioValue, setRadioValue] = useState(1);
  const windowWidth = window.innerWidth;
  const onRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

  const insertPsd = (e, step) => {
    const inputValue = e.target.value;
    if (step == "1") {
      if (inputValue) secondRef.current.focus();
      if (!inputValue) firstRef.current.focus();
    } else if (step == "2") {
      if (inputValue) thirdRef.current.focus();
      if (!inputValue) firstRef.current.focus();
    } else if (step == "3") {
      if (inputValue) fourthRef.current.focus();
      if (!inputValue) secondRef.current.focus();
    } else if (step == "4") {
      if (inputValue) fifthRef.current.focus();
      if (!inputValue) thirdRef.current.focus();
    } else if (step == "5") {
      if (inputValue) sixthRef.current.focus();
      if (!inputValue) fourthRef.current.focus();
    } else if (step == "6") {
      if (inputValue) sixthRef.current.focus();
      if (!inputValue) fifthRef.current.focus();
    }
  };

  const fillInMoney = () => {
    form.setFieldsValue({
      withdraw_amount: statisticValue,
    });
  };

  useEffect(() => {
    form.resetFields();
    setRadioValue(1);
  }, [isVisible]);

  return (
    <ModalWrapper
      width={550}
      centered={true}
      maskClosable={false}
      footer={null}
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
    >
      <Wrapper>
        <Form
          form={form}
          // labelCol={{ span: 4 }}
          // wrapperCol={{ span: 20 }}
          autoComplete="off"
          initialValues={{ withdraw_type: 1 }}
          onFinish={handleCreate}
          labelWrap
        >
          <FormTitle>{t("WithdrawApply.APPLY_WITHDRAW")}</FormTitle>
          <Form.Item label={t("Home.BALANCE")}>
            <div style={{ display: "flex" }}>
              <Value>{statisticValue}</Value>
              <RefreshButton type="link" onClick={handleFetchList}>
                <SyncOutlined />
              </RefreshButton>
            </div>
          </Form.Item>
          <Divider />
          <Form.Item
            label={"假的輸入欄位，為了避免瀏覽器自動填入登入的帳號密碼"}
            name="trade_password"
            style={{ position: "absolute", top: "10000vh" }}
          >
            {/* 假的輸入欄位，為了避免瀏覽器自動填入登入的帳號密碼 */}
            <input type="text" />
            <input type="password" />
          </Form.Item>
          <Form.Item
            label={t("WithdrawApply.WITHDRAW_TYPE")}
            name="withdraw_type"
            rules={[
              {
                required: true,
                message:
                  t("WithdrawApply.THIS_ITEM") +
                  t("WithdrawApply.MUST_FILL_IN"),
              },
            ]}
          >
            <Radio.Group onChange={onRadioChange} value={radioValue}>
              <Radio value={1}>USDT</Radio>
              <Radio value={2}>{t("WithdrawApply.BANK_CARD")}</Radio>
            </Radio.Group>
          </Form.Item>

          {radioValue == 2 && (
            <>
              <Form.Item
                label={t("WithdrawApply.BANK_NAME")}
                name="bank_name"
                rules={[
                  {
                    required: true,
                    message:
                      t("WithdrawApply.THIS_ITEM") +
                      t("WithdrawApply.MUST_FILL_IN"),
                  },
                ]}
              >
                <InputWrapper />
              </Form.Item>

              <Form.Item
                label={t("WithdrawApply.BANK_SUB_NAME")}
                name="bank_sub_name"
                rules={[
                  {
                    required: true,
                    message:
                      t("WithdrawApply.THIS_ITEM") +
                      t("WithdrawApply.MUST_FILL_IN"),
                  },
                ]}
              >
                <InputWrapper />
              </Form.Item>

              <Form.Item
                label={t("WithdrawApply.REAL_NAME")}
                name="real_name"
                rules={[
                  {
                    required: true,
                    message:
                      t("WithdrawApply.THIS_ITEM") +
                      t("WithdrawApply.MUST_FILL_IN"),
                  },
                ]}
              >
                <InputWrapper />
              </Form.Item>

              <Form.Item
                label={t("WithdrawApply.BANK_ACCOUNT")}
                name="bank_account"
                rules={[
                  {
                    required: true,
                    message:
                      t("WithdrawApply.THIS_ITEM") +
                      t("WithdrawApply.MUST_FILL_IN"),
                  },
                ]}
              >
                <InputWrapper />
              </Form.Item>
            </>
          )}
          {radioValue == 1 && (
            <>
              <Form.Item
                label={t("WithdrawApply.USDT_ADDRESS")}
                name="usdt_address"
                rules={[
                  {
                    required: true,
                    message:
                      t("WithdrawApply.THIS_ITEM") +
                      t("WithdrawApply.MUST_FILL_IN"),
                  },
                ]}
              >
                <InputWrapper placeholder={t("WithdrawApply.SUPPORT_TRC")} />
              </Form.Item>
            </>
          )}
          <div style={{ display: "flex" }}>
            <Form.Item
              label={t("WithdrawApply.WITHDRAW_AMOUNT")}
              name="withdraw_amount"
              rules={[
                {
                  required: true,
                  message:
                    t("WithdrawApply.THIS_ITEM") +
                    t("WithdrawApply.MUST_FILL_IN"),
                },
              ]}
            >
              <InputNumberWrapper />
            </Form.Item>
            <Form.Item>
              <FillBtn
                mobile={windowWidth < 768 ? true : false}
                onClick={fillInMoney}
              >
                {t("WithdrawApply.ALL")}
              </FillBtn>
            </Form.Item>
          </div>
          <Row>
            <FirstCol md={7} xs={4} mobile={windowWidth < 768 ? true : false}>
              <Form.Item
                label={t("WithdrawApply.MONEY_PASSWORD")}
                name="money_password1"
                rules={[
                  { required: true, message: t("WithdrawApply.MUST_FILL_IN") },
                ]}
                labelCol={{ span: windowWidth < 768 ? 24 : 0 }}
              >
                <InputVerifyWrapper
                  onChange={(e) => insertPsd(e, "1")}
                  focus
                  ref={firstRef}
                  maxLength={1}
                />
              </Form.Item>
            </FirstCol>
            <AnotherCol md={3} xs={4} mobile={windowWidth < 768 ? true : false}>
              <Form.Item
                name="money_password2"
                rules={[
                  { required: true, message: t("WithdrawApply.MUST_FILL_IN") },
                ]}
              >
                <InputVerifyWrapper
                  onChange={(e) => insertPsd(e, "2")}
                  focus
                  ref={secondRef}
                  maxLength={1}
                />
              </Form.Item>
            </AnotherCol>
            <AnotherCol md={3} xs={4} mobile={windowWidth < 768 ? true : false}>
              <Form.Item
                name="money_password3"
                rules={[
                  { required: true, message: t("WithdrawApply.MUST_FILL_IN") },
                ]}
              >
                <InputVerifyWrapper
                  onChange={(e) => insertPsd(e, "3")}
                  focus
                  ref={thirdRef}
                  maxLength={1}
                />
              </Form.Item>
            </AnotherCol>
            <AnotherCol md={3} xs={4} mobile={windowWidth < 768 ? true : false}>
              <Form.Item
                name="money_password4"
                rules={[
                  { required: true, message: t("WithdrawApply.MUST_FILL_IN") },
                ]}
              >
                <InputVerifyWrapper
                  onChange={(e) => insertPsd(e, "4")}
                  focus
                  ref={fourthRef}
                  maxLength={1}
                />
              </Form.Item>
            </AnotherCol>
            <AnotherCol md={3} xs={4} mobile={windowWidth < 768 ? true : false}>
              <Form.Item
                name="money_password5"
                rules={[
                  { required: true, message: t("WithdrawApply.MUST_FILL_IN") },
                ]}
              >
                <InputVerifyWrapper
                  onChange={(e) => insertPsd(e, "5")}
                  focus
                  ref={fifthRef}
                  maxLength={1}
                />
              </Form.Item>
            </AnotherCol>
            <AnotherCol md={3} xs={4} mobile={windowWidth < 768 ? true : false}>
              <Form.Item
                name="money_password6"
                rules={[
                  { required: true, message: t("WithdrawApply.MUST_FILL_IN") },
                ]}
              >
                <InputVerifyWrapper
                  onChange={(e) => insertPsd(e, "6")}
                  focus
                  ref={sixthRef}
                  maxLength={1}
                />
              </Form.Item>
            </AnotherCol>
          </Row>

          <BtnCreateAcct htmlType="submit">
            {t("WithdrawApply.APPLY")}
          </BtnCreateAcct>
        </Form>
      </Wrapper>
    </ModalWrapper>
  );
};

export default CreateModal;
