import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "locales/en.json";
import zh from "locales/zh.json";
import zh_cn from "locales/zh_cn.json";

const debug = process.env.NODE_ENV === "development";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug,
    resources: {
      en: {
        translation: en,
      },
      zh: {
        translation: zh,
      },
      zh_cn: {
        translation: zh_cn,
      },
    },
    lng: "zh_cn", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    // fallbackLng: "en",
    returnObjects: true, // 設定 值可回傳 Object
    joinArrays: "<br/>", // 設定 值若為 Array, 回傳值為 Array.join('<br/>')

    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    preload: true,
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
    },
  });

export default i18n;
