import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormTitle = styled.h2`
  font-weight: 300;
  color: #fff;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #3f3f3f;
`;

export const QRCodeWrapper = styled.div`
  border: 15px solid #fff;
`;
