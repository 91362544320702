import React, { useState, useEffect } from "react";
import { useImmer } from "use-immer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Form, Input, Button } from "antd";
import { UndoOutlined } from "@ant-design/icons";

import Set2FAModal from "./component/Set2FAModal";
import actions from "redux/action";
import { fetchLogin, fetchVerifyId, fetchVerifyImg, fetchMe } from "api";
import {
  Wrapper,
  LoginWrapper,
  Title,
  LoginButton,
  ForgetPassword,
  UserIcon,
  PasswordIcon,
  CodeIcon,
  RefreshIcon,
  LogInTitle,
  InputWrapper,
  InputPasswordWrapper,
  ReConfirmBtn,
} from "./Styled";

const iconStyles = {
  marginLeft: "16px",
  color: "rgba(0, 0, 0, 0.2)",
  fontSize: "24px",
  verticalAlign: "middle",
  cursor: "pointer",
};

export default function Login() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [captchaId, setCaptchaId] = useState("");
  const [reload, setReload] = useState(false);
  // const [state, updateState] = useImmer({
  //   modalVisible: false,
  //   qrcode: "",
  //   user_name: "",
  //   password: "",
  // });

  const handleLogin = function () {
    const requestBody = form.getFieldsValue();
    requestBody.captcha_id = captchaId;
    console.log("requestBody", requestBody);
    fetchLogin(requestBody)
      .then((response) => {
        if (response?.access_token) {
          dispatch(actions.updateUser(requestBody.user_name));
          localStorage.access_token = response.access_token;
          localStorage.token_expired = response.expires_at;
          dispatch(actions.tradPsdStatus(response?.first_money_password));
          // 登入成功，紀錄用戶輸入的登入密碼
          dispatch(actions.recordPsd(requestBody.password));
          // 改成監控登入密碼
          dispatch(actions.psdStatus(response?.is_set_password));
          // if (response?.first_money_password == 1) {
          //   dispatch(actions.onModalToggle(true));
          // }
          // else {
          //   dispatch(actions.tradPsdStatus(2));
          // }
          fetchMe().then((res) => {
            dispatch(actions.updateMode(res.mode));
            dispatch(actions.updateRecord(res));
            dispatch(actions.storeInviteCode(res.invite_code));
          });
        }
        // else {
        //   updateState((draft) => {
        //     draft.modalVisible = true;
        //     draft.qrcode = response.qrcode;
        //     draft.user_name = requestBody.user_name;
        //     draft.password = requestBody.password;
        //   });
        // }
      })
      .catch((e) => {
        alert(e?.message);
      })
      .finally(() => {
        // dispatch(actions.updateUser(requestBody.user_name));
      });
  };

  useEffect(() => {
    fetchVerifyId().then((res) => {
      setCaptchaId(res.captcha_id);
      setImage(
        process.env.REACT_APP_BASE_URL.concat(
          `/agent/captcha?id=${res.captcha_id}`
        )
      );
    });
  }, [reload]);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Wrapper>
      <LoginWrapper>
        <Form
          form={form}
          name="login"
          onFinish={handleLogin}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <LogInTitle>Log In</LogInTitle>
          <Title>{t("LogIn.TITLE")}</Title>
          <Form.Item
            name="user_name"
            rules={[
              {
                required: true,
                message: t("LogIn.RULE_MSG") + t("LogIn.USER_NAME"),
              },
              {
                pattern: new RegExp("^[0-9a-zA-Z,_-]{1,}$", "g"),
                message: t("LogIn.REGULAR_RULE_MSG"),
              },
            ]}
          >
            <InputWrapper
              placeholder={t("LogIn.USER_NAME")}
              prefix={<UserIcon />}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t("LogIn.RULE_MSG") + t("LogIn.PASSWORD"),
              },
            ]}
          >
            <InputPasswordWrapper
              placeholder={t("LogIn.PASSWORD")}
              prefix={<PasswordIcon />}
            />
          </Form.Item>
          <Form.Item name="captcha_code">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
              }}
            >
              <InputWrapper
                placeholder={t("LogIn.CONFIRM_CODE")}
                prefix={<CodeIcon />}
              />
              <img
                src={image}
                style={{
                  transform: "scale(1)",
                  width: "30%",
                  marginLeft: "20px",
                }}
              ></img>
              <ReConfirmBtn
                onClick={() => setReload((v) => !v)}
                type="link"
                shape="circle"
                icon={<RefreshIcon />}
              />
            </div>
          </Form.Item>
          {/* <Form.Item
            name="captcha_code"
            rules={[{ required: true, message: "請輸入驗證碼!" }]}
          >
            <InputWrapper placeholder={"驗證碼"} prefix={<CodeIcon />} />
          </Form.Item> */}

          {/* {!state.modalVisible && ( */}
          <LoginButton type="primary" htmlType="submit">
            登入
          </LoginButton>
          {/* )} */}
          {/* <ForgetPassword>忘記密碼</ForgetPassword> */}
        </Form>

        {/* <Set2FAModal
          state={state}
          updateState={updateState}
          handleLogin={handleLogin}
        /> */}
      </LoginWrapper>
    </Wrapper>
  );
}
