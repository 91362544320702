import {
  Button,
  Space,
  Popconfirm,
  Image,
  Switch,
  Empty,
  Typography,
  notification,
} from "antd";
import moment from "moment";
import keyBy from "lodash/keyBy";
import { areaOptions, genderOptions } from "options";
import EditIcon from "assets/icons/edit.png";
import DeleteIcon from "assets/icons/delete.png";
const theDate = moment(new Date().getTime()).format("yyyyMMDD");
const objectArea = keyBy(areaOptions, (e) => e.value);
const objectGender = keyBy(genderOptions, (e) => e.value);
const { Paragraph } = Typography;
// 下方表格
export const Columns = ({ t, inviteCode, mode, height, windowWidth }) => [
  {
    dataIndex: "domain_name",
    key: "domain_name",
    align: "left",
    ellipsis: true,
    render: (text, record) => {
      return mode == 3 ? (
        <div style={{ display: "flex" }}>
          <Paragraph>{text.replace("{CPC}", inviteCode)}</Paragraph>
        </div>
      ) : (
        <div style={{ display: "flex" }}>
          <Paragraph>{`${text}?c=${inviteCode}`}</Paragraph>
        </div>
      );
    },
  },
  {
    dataIndex: "copy",
    key: "copy",
    render: (text, record) => {
      return mode == 3 ? (
        <div style={{ display: windowWidth < 768 ? "block" : "flex" }}>
          <Paragraph
            copyable={{
              icon: [
                <Button style={{ marginLeft: "30px" }} shape="round">
                  {t("PromoLink.COPY")}
                </Button>,
                <Button
                  style={{
                    marginLeft: "30px",
                    backgroundColor: "#D73A63",
                    color: "#fff",
                  }}
                  shape="round"
                >
                  {t("PromoLink.COPY")}
                </Button>,
              ],
              onCopy: () =>
                notification.open({
                  message: t("PromoLink.COPY_DONE"),
                  placement: "top",
                  className: "copyParagraph",
                  closeIcon: null,
                  top: height / 2,
                }),
              text: `${record.domain_name.replace("{CPC}", inviteCode)}`,
            }}
          ></Paragraph>
          {record.domain_name.includes("p1") && (
            <p style={{ margin: "5px 0 0 30px" }}>{t("PromoLink.SIMPLE")}</p>
          )}
          {record.domain_name.includes("p2") && (
            <p style={{ margin: "5px 0 0 30px" }}>{t("PromoLink.SEXUAL")}</p>
          )}
        </div>
      ) : (
        <div style={{ display: windowWidth < 768 ? "block" : "flex" }}>
          <Paragraph
            copyable={{
              icon: [
                <Button style={{ marginLeft: "30px" }} shape="round">
                  {t("PromoLink.COPY")}
                </Button>,
                <Button
                  style={{
                    marginLeft: "30px",
                    backgroundColor: "#D73A63",
                    color: "#fff",
                  }}
                  shape="round"
                >
                  {t("PromoLink.COPY")}
                </Button>,
              ],
              onCopy: () =>
                notification.open({
                  message: t("PromoLink.COPY_DONE"),
                  placement: "top",
                  className: "copyParagraph",
                  closeIcon: null,
                  top: height / 2,
                }),
              text: `${record.domain_name}?c=${inviteCode}`,
            }}
          ></Paragraph>
          {record.domain_name.includes("c1") && (
            <p style={{ margin: "5px 0 0 30px" }}>{t("PromoLink.SIMPLE")}</p>
          )}
          {record.domain_name.includes("c2") && (
            <p style={{ margin: "5px 0 0 30px" }}>{t("PromoLink.SEXUAL")}</p>
          )}
        </div>
      );
    },
  },
];

export const Columns_BackUp = ({
  t,
  inviteCode,
  mode,
  height,
  windowWidth,
}) => [
  {
    dataIndex: "domain_name",
    key: "domain_name",
    align: "left",
    ellipsis: true,
    render: (text, record) => {
      return mode == 3 ? (
        <div style={{ display: "flex" }}>
          <Paragraph>{text.replace("{CPC}", inviteCode)}</Paragraph>
        </div>
      ) : (
        <div style={{ display: "flex" }}>
          <Paragraph>{`${text}?c=${inviteCode}`}</Paragraph>
        </div>
      );
    },
  },
  {
    dataIndex: "copy",
    key: "copy",
    render: (text, record) => {
      return mode == 3 ? (
        <div style={{ display: windowWidth < 768 ? "block" : "flex" }}>
          <Paragraph
            copyable={{
              icon: [
                <Button style={{ marginLeft: "30px" }} shape="round">
                  {t("PromoLink.COPY")}
                </Button>,
                <Button
                  style={{
                    marginLeft: "30px",
                    backgroundColor: "#D73A63",
                    color: "#fff",
                  }}
                  shape="round"
                >
                  {t("PromoLink.COPY")}
                </Button>,
              ],
              onCopy: () =>
                notification.open({
                  message: t("PromoLink.COPY_DONE"),
                  placement: "top",
                  className: "copyParagraph",
                  closeIcon: null,
                  top: height / 2,
                }),
              text: `${record.domain_name.replace("{CPC}", inviteCode)}`,
            }}
          ></Paragraph>
          {record.domain_name.includes("p1") && (
            <p style={{ margin: "5px 0 0 30px" }}>{t("PromoLink.SIMPLE")}</p>
          )}
          {record.domain_name.includes("p2") && (
            <p style={{ margin: "5px 0 0 30px" }}>{t("PromoLink.SEXUAL")}</p>
          )}
        </div>
      ) : (
        <div style={{ display: windowWidth < 768 ? "block" : "flex" }}>
          <Paragraph
            copyable={{
              icon: [
                <Button style={{ marginLeft: "30px" }} shape="round">
                  {t("PromoLink.COPY")}
                </Button>,
                <Button
                  style={{
                    marginLeft: "30px",
                    backgroundColor: "#D73A63",
                    color: "#fff",
                  }}
                  shape="round"
                >
                  {t("PromoLink.COPY")}
                </Button>,
              ],
              onCopy: () =>
                notification.open({
                  message: t("PromoLink.COPY_DONE"),
                  placement: "top",
                  className: "copyParagraph",
                  closeIcon: null,
                  top: height / 2,
                }),
              text: `${record.domain_name}?c=${inviteCode}`,
            }}
          ></Paragraph>
          {record.domain_name.includes("c1") && (
            <p style={{ margin: "5px 0 0 30px" }}>{t("PromoLink.SIMPLE")}</p>
          )}
          {record.domain_name.includes("c2") && (
            <p style={{ margin: "5px 0 0 30px" }}>{t("PromoLink.SEXUAL")}</p>
          )}
        </div>
      );
    },
  },
];

export const Columns_Apk = ({ t, inviteCode, height, windowWidth }) => [
  {
    dataIndex: "url",
    key: "url",
    align: "left",
    ellipsis: true,
    render: (text, record) => {
      return (
        <div style={{ display: "flex" }}>
          <Paragraph>{`${text}/wx_app_${theDate}_${inviteCode}.apk`}</Paragraph>
        </div>
      );
    },
  },
  {
    dataIndex: "copy",
    key: "copy",
    render: (text, record) => {
      return (
        <div style={{ display: windowWidth < 768 ? "block" : "flex" }}>
          <Paragraph
            copyable={{
              icon: [
                <Button style={{ marginLeft: "30px" }} shape="round">
                  {t("PromoLink.COPY")}
                </Button>,
                <Button
                  style={{
                    marginLeft: "30px",
                    backgroundColor: "#D73A63",
                    color: "#fff",
                  }}
                  shape="round"
                >
                  {t("PromoLink.COPY")}
                </Button>,
              ],
              onCopy: () =>
                notification.open({
                  message: t("PromoLink.COPY_DONE"),
                  placement: "top",
                  className: "copyParagraph",
                  closeIcon: null,
                  top: height / 2,
                }),
              text: `${record.url}/wx_app_${theDate}_${inviteCode}.apk`,
            }}
          ></Paragraph>
          {record.url.includes("p1") && (
            <p style={{ margin: "5px 0 0 30px" }}>{t("PromoLink.SIMPLE")}</p>
          )}
          {record.url.includes("p2") && (
            <p style={{ margin: "5px 0 0 30px" }}>{t("PromoLink.SEXUAL")}</p>
          )}
        </div>
      );
    },
  },
];
