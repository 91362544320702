import { notification } from "antd";

export const SuccessNotify = (title, description) => {
  notification["success"]({
    message: `${title}`,
    description: `${description ? description : ""}`,
  });
};

export const InfoNotify = (title, description) => {
  notification["info"]({
    message: `${title}`,
    description: `${description ? description : ""}`,
  });
};

export const WarningNotify = (title, description) => {
  notification["warning"]({
    message: `${title}`,
    description: `${description ? description : ""}`,
  });
};

export const ErrorNotify = (title, description) => {
  notification["error"]({
    message: `${title}`,
    description: `${description ? description : ""}`,
  });
};
