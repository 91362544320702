import React, { useState } from "react";
import { Button, Drawer, Space } from "antd";
import { DrawerWrapper } from "./Styled";
import { MenuOutlined } from "@ant-design/icons";
import SideMenu from "component/SideMenu";

const DrawerSideMenu = (props) => {
  return (
    <div>
      {/* <Button
        type="link"
        onClick={props.showDrawer}
        style={{ position: "absolute", right: "10px", top: "10px", zIndex: 1 }}
      >
        <MenuOutlined />
      </Button> */}
      <DrawerWrapper
        placement="left"
        width={200}
        onClose={props.onClose}
        closable={false}
        visible={props.open}
        getContainer={false}
        extra={
          <Space>
            <Button type="primary" onClick={props.onClose}>
              OK
            </Button>
          </Space>
        }
      >
        {props.children}
      </DrawerWrapper>
    </div>
  );
};

export default DrawerSideMenu;
