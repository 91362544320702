import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "Nova",
  sideMenuFullWidth: false,
};

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    initApp: (state, action) => {
      state.name = initialState.name;
      state.sideMenuFullWidth = initialState.sideMenuFullWidth;
    },
    updateAppName: (state, action) => {
      state.name = action.payload;
    },
    updateSideMenuFullWidth: (state, action) => {
      state.sideMenuFullWidth = !state.sideMenuFullWidth;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;
