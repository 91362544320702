import { Button, Space, Popconfirm } from "antd";
import { Switch, Dropdown, Menu } from "antd";
import keyBy from "lodash/keyBy";
import { statusOptions, agentModeOptions } from "options";
import EditIcon from "assets/icons/edit.png";
import DeleteIcon from "assets/icons/delete.png";
import { SmallDashOutlined, LockOutlined } from "@ant-design/icons";
import { timeFormat } from "functions/timeFormat";

const objectStatus = keyBy(statusOptions, (e) => e.value);
const agentMode = keyBy(agentModeOptions, (e) => e.value);
const menu = (
  record,
  showCreatePrePayModal,
  showCreateNextLevelModal,
  showTradeListModal
) => {
  return (
    <Menu>
      <Menu.Item key="0">
        <Button type="link" onClick={() => showCreatePrePayModal(record)}>
          新增預付
        </Button>
      </Menu.Item>
      {record.level !== 5 && (
        <Menu.Item key="1">
          <Button type="link" onClick={() => showCreateNextLevelModal(record)}>
            新增下級
          </Button>
        </Menu.Item>
      )}

      <Menu.Item key="2">
        <Button type="link" onClick={() => showTradeListModal(record)}>
          交易流水
        </Button>
      </Menu.Item>
    </Menu>
  );
};

// 下方表格
export const Columns = ({
  handleUpdate,
  handleDelete,
  showModifyModal,
  showCreatePrePayModal,
  showCreateNextLevelModal,
  showTradeListModal,
  showResetPsdModal,
}) => [
  {
    title: "代理信息",
    dataIndex: "user_name",
    key: "user_name",
    width: 180,
    render: (text, record) => {
      return (
        <>
          <span>帳號:{text}</span>
          <p>暱稱:{record?.name}</p>
          <p>模式:{agentMode[record.mode]?.label}</p>
        </>
      );
    },
  },
  {
    //級別(1~5)
    title: "代理級別",
    dataIndex: "level",
    key: "level",
    width: 180,
    render: (text, record) => {
      return (
        <>
          <p>等級:{text}級</p>
          <p>上級:{record?.parent_user_name || "-"}</p>
          <p>下級:{record?.sub_agent_count || "-"}</p>
        </>
      );
    },
  },
  {
    title: "累積營收狀況(TBD)",
    dataIndex: "name",
    key: "name",
    width: 180,
    render: (text, record) => {
      return (
        <>
          <p>全線充值:{text}</p>
          <p>全線返佣:{record?.parent_user_name || "-"}</p>
          <p>手續費:{record?.sub_agent_count || "-"}</p>
          <p>平台營收盈利:{record?.sub_agent_count || "-"}</p>
        </>
      );
    },
  },
  {
    title: "帳戶餘額",
    dataIndex: "prepay_balance",
    key: "prepay_balance",
    width: 100,
  },
  {
    title: "直屬人數",
    dataIndex: "sub_member_count",
    key: "sub_member_count",
    width: 100,
    render: (text) => text || 0,
  },
  {
    title: "團隊人數",
    dataIndex: "total_member_count",
    key: "total_member_count",
    width: 100,
    render: (text) => text || 0,
  },
  {
    title: "直屬返佣(TBD)",
    dataIndex: "sub_member_total_commission",
    key: "sub_member_total_commission",
    width: 100,
  },
  {
    title: "代理返佣(TBD)",
    dataIndex: "name",
    key: "name",
    width: 100,
  },
  {
    title: "累積返佣(TBD)",
    dataIndex: "name",
    key: "name",
    width: 100,
  },

  // {
  //   title: "代理暱稱",
  //   dataIndex: "name",
  //   key: "name",
  //   width: 100,
  // },
  // {
  //   //代理模式(1:CPA 2:CPS)
  //   title: "代理模式",
  //   dataIndex: "mode",
  //   key: "mode",
  //   width: 100,
  //   render: (text) => agentMode[text].label,
  // },

  // {
  //   title: "邀請碼",
  //   dataIndex: "invite_code",
  //   key: "invite_code",
  //   width: 100,
  //   render: (text) => text || "-",
  // },
  // {
  //   //上級代理帳號
  //   title: "上級代理",
  //   dataIndex: "parent_user_name",
  //   key: "parent_user_name",
  //   width: 100,
  //   render: (text) => text || "-",
  // },
  // {
  //   title: "下級代理人數",
  //   dataIndex: "sub_agent_count",
  //   key: "sub_agent_count",
  //   width: 110,
  //   render: (text) => text || 0,
  // },

  // {
  //   title: "佣金",
  //   dataIndex: "total_commission",
  //   key: "total_commission",
  //   width: 100,
  // },

  {
    title: "代理狀態",
    dataIndex: "status",
    key: "status",
    width: 100,
    render: (_, record) => {
      return (
        <Switch
          checkedChildren={objectStatus[1]?.label}
          unCheckedChildren={objectStatus[2]?.label}
          checked={!(_ >> 1)}
          onChange={(isCheck) =>
            handleUpdate({ ...record, status: isCheck ? 1 : 2 }, record?.id)
          }
        />
      );
    },
  },
  // {
  //   title: "返佣狀態",
  //   dataIndex: "commission_state",
  //   key: "commission_state",
  //   width: 100,
  //   render: (_, record) => {
  //     return (
  //       <Switch
  //         checkedChildren={objectStatus[1]?.label}
  //         unCheckedChildren={objectStatus[2]?.label}
  //         checked={!(_ >> 1)}
  //         onChange={(isCheck) =>
  //           handleUpdate(
  //             { ...record, commission_state: isCheck ? 1 : 2 },
  //             record.id
  //           )
  //         }
  //       />
  //     );
  //   },
  // },
  {
    title: "創建時間",
    dataIndex: "created_at",
    key: "created_at",
    width: 180,
    sorter: (a, b) => a.created_at - b.created_at,
    render: (text) => timeFormat(text),
  },
  {
    title: "操作",
    dataIndex: "actions",
    key: "actions",
    fixed: "right",
    width: 120,
    render: (_, record) => {
      if (record) {
        return (
          <Space size={"middle"} style={{ background: "transparent" }}>
            <img
              src={EditIcon}
              alt="edit"
              onClick={() => {
                showModifyModal(record);
              }}
            />
            <LockOutlined onClick={() => showResetPsdModal(record)} />
            <Dropdown
              overlay={menu(
                record,
                showCreatePrePayModal,
                showCreateNextLevelModal,
                showTradeListModal
              )}
              trigger={["click"]}
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                style={{ color: "#fff" }}
              >
                <SmallDashOutlined />
              </a>
            </Dropdown>
          </Space>
        );
      }
    },
  },
];
