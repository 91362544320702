import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import actions from "redux/action";
import TradePsdModal from "component/TradePsdModal";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { SyncOutlined } from "@ant-design/icons";
import { GreenButton, BlueButton, RedButton } from "styles/component";
import {
  SuccessNotify,
  ErrorNotify,
} from "component/Notification/Notification";
import {
  OutsideWrapper,
  Title,
  TableContainer,
  DividerLine,
  ButtonBlock,
  FormWrapper,
  CardWrapper,
  OrangeButton,
} from "./Styled";
import { RefreshButton } from "./component/CreateModal/Styled";
import CreateModal from "./component/CreateModal";
// import ModifyModal from "./component/ModifyModal";
import {
  fetchWithdraw,
  fetchPostWithdraw,
  fetchPutWithdraw,
  fetchHomepage,
  fetchPutTradePassword,
} from "api/index";
import { Columns } from "./data";
import { FormItem, RangePicker, SpaceWrapper } from "./Styled";
import { genderFilterOptions, statusFilterOptions } from "../../options";
import { notification, message } from "antd";
import { selectSetTradePsd, selectIsTradeVisible } from "redux/selector/user";

const WithdrawApply = () => {
  const { t } = useTranslation();
  const windowWidth = window.innerWidth;
  // 設置今天
  const today = new Date();
  // 設置七天前
  const sevenDays = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 7
  );
  const [form] = FormWrapper.useForm();
  const [tradeForm] = FormWrapper.useForm();
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [statisticData, setStatisticData] = useState({});
  const [total, setTotal] = useState(1);
  const [rangeDate, setRangeDate] = useState([sevenDays, today]);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  //是否尚未設定交易密碼
  const dispatch = useDispatch();
  const setTradePsdStatus = useSelector(selectSetTradePsd);
  const isTradeVisible = useSelector(selectIsTradeVisible);
  const [withdrawTableData, setWithdrawTableData] = useState([]);
  const regOrderNo = new RegExp("^\\d{6}$");
  // const [isModifyModalVisible, setIsModifyModalVisible] = useState(false);
  const [record, setRecord] = useState({});

  const handleFetchList = () => {
    let requestBody = form.getFieldsValue();
    requestBody.created_at_begin = moment(rangeDate[0])?.format(
      "yyyy-MM-DD 00:00:00"
    );
    requestBody.created_at_end = moment(rangeDate[1])?.format(
      "yyyy-MM-DD 23:59:59"
    );

    fetchHomepage().then((res) => {
      setStatisticData(res);
    });
    fetchWithdraw({ ...pagination, ...requestBody }).then((response) => {
      const { data, page_result } = response;
      setTableData(data);
      setTotal(page_result.total);
    });
    fetchWithdraw({ current: 1, pageSize: 999 }).then((response) => {
      setWithdrawTableData(response.data);
    });
  };

  const handleCreate = (params) => {
    console.log("params", params);
    params.money_password = params.money_password1.concat(
      params.money_password2,
      params.money_password3,
      params.money_password4,
      params.money_password5,
      params.money_password6
    );
    let bodyFormData = new FormData();

    fetchPostWithdraw(params)
      .then((response) => {
        // initializeForm();
        SuccessNotify("新增成功");
        setIsCreateModalVisible(false);
        handleFetchList();
      })
      .catch((error) => {
        ErrorNotify(`${error.message}`);
      });
  };

  const handleCancel = (id) => {
    fetchPutWithdraw(id)
      .then((response) => {
        handleFetchList();
        SuccessNotify("刪除成功");
      })
      .catch((e) => {
        ErrorNotify("刪除失敗，請聯繫技術");
      });
  };

  // 首次設定提現密碼
  const handleFirstSetting = (params) => {
    let old_password = params.old_password;
    let new_password = params.new_password;
    fetchPutTradePassword({ old_password, new_password })
      .then((res) => {
        SuccessNotify("修改成功");
        // setIsVisible(false);
        dispatch(actions.onModalToggle(false));
        dispatch(actions.tradPsdStatus(2));
        tradeForm.resetFields();
        // setIsModalVisible(true);
      })
      .catch((error) => {
        ErrorNotify(error.message);
      });
  };

  const handleCancelChangeTrade = () => {
    if (setTradePsdStatus !== 1) {
      dispatch(actions.onModalToggle(false));
    } else {
      message.warning(t("WithdrawApply.TRADE_PASSWORD_MSG"));
      dispatch(actions.onModalToggle(true));
    }
  };
  const showCreateModal = () => {
    if (setTradePsdStatus == 1) {
      dispatch(actions.onModalToggle(true));
    } else if (withdrawTableData.find((item) => item.status == 1)) {
      ErrorNotify(t("WithdrawApply.ONE_TRADE"));
    } else {
      setIsCreateModalVisible(true);
    }
  };

  // 日期變更時
  const onDateChange = (_, dateString) => {
    setRangeDate(dateString);
  };

  // const showModifyModal = (record) => {
  //   setIsModifyModalVisible(true);
  //   setRecord(record);
  // };

  const initializeForm = () => {
    setPagination({
      current: 1,
      pageSize: 10,
    });
  };

  // 表格中編輯、刪除、儲存、取消
  const onPageUpdate = (paginate) => {
    setPagination(paginate);
  };

  // 日期選擇限制
  const disabledDate = (current) => {
    if (!rangeDate || rangeDate.length === 0) {
      return false;
    }
    // 不可选择的日期
    const tooLate = rangeDate[0] && current.diff(rangeDate[0], "days") > 7;
    const tooEarly =
      rangeDate[1] && moment(rangeDate[0]).diff(current, "days") >= 7;
    return tooEarly || tooLate;
  };

  const columns = Columns({
    t,
    handleCancel,
    // showModifyModal,
  });

  useEffect(() => {
    handleFetchList();
  }, [pagination]);

  useEffect(() => {
    // fetchVideoRegion({ ...pagination }).then((res) => {
    //   res.data.unshift({ id: "0", name: "無" });
    //   setRegionData(res.data);
    // });
  }, []);

  return (
    <OutsideWrapper>
      {/* <DividerLine orientation="left">
        <Title level={3}>演員管理</Title>
      </DividerLine> */}

      {/* 
      <FormWrapper
        layout={"inline"}
        form={form}
        onFinish={initializeForm}
        autoComplete="off"
        initialValues={{
          gender: 0,
          status: 0,
        }}
      >
        <FormItem label="演員名字(中)" name="name">
          <InputContainer />
        </FormItem>

        <FormItem label="演員名字(原)" name="orig_name">
          <InputContainer />
        </FormItem>

        <FormItem label="性別" name="gender">
          <SelectWrapper>
            {genderFilterOptions.map(({ value, label }, i) => {
              return (
                <Option key={i} value={value}>
                  {label}
                </Option>
              );
            })}
          </SelectWrapper>
        </FormItem>

        <FormItem label="狀態" name="status">
          <SelectWrapper>
            {statusFilterOptions.map(({ value, label }, i) => {
              return (
                <Option key={i} value={value}>
                  {label}
                </Option>
              );
            })}
          </SelectWrapper>
        </FormItem>

        <FormItem>
          <SpaceWrapper>
            <GreenButton>篩選</GreenButton>
            <RedButton
              onClick={() => {
                form.resetFields();
              }}
            >
              重置
            </RedButton>
          </SpaceWrapper>
        </FormItem>
      </FormWrapper> */}
      <CardWrapper>
        <FormWrapper
          layout={windowWidth < 768 ? "inline" : ""}
          form={form}
          onFinish={initializeForm}
          autoComplete="off"
          initialValues={{}}
        >
          <div
            style={{
              display: windowWidth < 768 ? "block" : "flex",
              justifyContent: "space-between",
            }}
          >
            <FormItem label={t("WithdrawApply.DATE")} name="date">
              <RangePicker
                // showTime
                onCalendarChange={onDateChange}
                disabledDate={disabledDate}
                defaultValue={[moment(sevenDays), moment(today)]}
              ></RangePicker>
            </FormItem>

            <FormItem style={{ textAlign: "right" }}>
              <SpaceWrapper>
                <BlueButton>{t("WithdrawApply.FILTER")}</BlueButton>
                <RedButton
                  onClick={() => {
                    form.resetFields();
                    setRangeDate([sevenDays, today]);
                  }}
                >
                  {t("WithdrawApply.RESET")}
                </RedButton>
              </SpaceWrapper>
            </FormItem>
          </div>
        </FormWrapper>
      </CardWrapper>
      <CardWrapper
        title={
          <>
            {t("WithdrawApply.BALANCE")}:
            <span style={{ color: "#448EF7" }}>{statisticData.balance}</span>
            <RefreshButton type="link" onClick={handleFetchList}>
              <SyncOutlined />
            </RefreshButton>
          </>
        }
        extra={
          <>
            <ButtonBlock>
              <OrangeButton onClick={showCreateModal}>
                {t("WithdrawApply.APPLY_WITHDRAW")}
              </OrangeButton>
            </ButtonBlock>
          </>
        }
      >
        <TableContainer
          scroll={{ x: "769px" }}
          columns={columns}
          dataSource={tableData}
          onChange={onPageUpdate}
          pagination={{
            ...pagination,
            total,
          }}
        />
      </CardWrapper>
      <TradePsdModal
        isVisible={isTradeVisible}
        onCancel={handleCancelChangeTrade}
        form={tradeForm}
        onFinish={handleFirstSetting}
        regOrderNo={regOrderNo}
        setTradePsdStatus={setTradePsdStatus}
      />
      <CreateModal
        isVisible={isCreateModalVisible}
        setIsVisible={setIsCreateModalVisible}
        handleCreate={handleCreate}
        statisticValue={statisticData.balance}
        handleFetchList={handleFetchList}
      />

      {/* <ModifyModal
        isVisible={isModifyModalVisible}
        setIsVisible={setIsModifyModalVisible}
        record={record}
      /> */}
    </OutsideWrapper>
  );
};

export default WithdrawApply;
