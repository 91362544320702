import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Layout, Tag, Tabs, Breadcrumb, Drawer } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import actions from "redux/action";
import "./App.css";
import Navbar from "component/Navbar";
import SideMenu from "component/SideMenu";
import DrawerSideMenu from "component/DrawerSideMenu";
import BreadCrumbComp from "component/BreadCrumb";
import { dataSideMenuItems } from "component/SideMenu/data";
import useVerifyToken from "hook/useVerifyToken";
import { fetchLogout } from "api";

const { Content } = Layout;
/**
 * 【 react-router-dom 】: https://reactrouter.com/docs/en/v6/getting-started/overview
 *
 * */

export default function App() {
  const width = window.innerWidth;
  const { TabPane } = Tabs;
  const { pathname } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();
  const username = useVerifyToken();
  const [tagGroup, setTagGroup] = useState([]);
  const [activeKey, setActiveKey] = useState("");
  const [selectMenuKey, setSelectMenuKey] = useState("qq");
  const [open, setOpen] = useState(false); // 控制抽屜的開關
  const [openMenuKey, setOpenMenuKey] = useState("");
  const dispatch = useDispatch();

  const { t } = useTranslation();
  // 打開抽屜
  const showDrawer = () => {
    setOpen(true);
  };
  // 關閉抽屜
  const onClose = () => {
    setOpen(false);
  };
  const remove = (targetKey) => {
    let lastIndex;
    tagGroup.forEach((tag, i) => {
      if (tag.path === targetKey) {
        lastIndex = i - 1;
      }
    });
    const tempTags = tagGroup.filter((tag) => tag.path !== targetKey);
    if (tempTags.length && activeKey === targetKey) {
      if (lastIndex >= 0) {
        setActiveKey(tempTags[lastIndex].path);
      } else {
        setActiveKey(tempTags[0].path);
      }
    }
    setActiveKey(activeKey);
    setTagGroup(tempTags);
  };

  // 登出
  const handleSwitchSideMenu = () => {
    dispatch(actions.updateSideMenuFullWidth());
    localStorage.removeItem("access_token");
    fetchLogout();
    setOpen(false);
  };

  const handleItemWrapperClick = (path, childrenPath, childrenTextKey) => {
    // console.log("麵包被點");
    // 如果在tagGroup裡面已經有t(`SideMenuItems.${childrenTextKey}`)了，就不再增加到陣列中
    if (
      !tagGroup.some((e) => e.itemName == t(`SideMenuItems.${childrenTextKey}`))
    ) {
      setTagGroup([
        ...tagGroup,
        {
          path: `${path}/${childrenPath}`,
          itemName: t(`SideMenuItems.${childrenTextKey}`),
          closable: true,
        },
      ]);
    }
    setActiveKey(`${path}/${childrenPath}`);
    navigate(`${path}/${childrenPath}`);
  };
  const handleItemClick = (path, itemName) => {
    // 如果在tagGroup裡面已經有itemName(t(`SideMenuItems.${textKey}`))了，就不再增加到陣列中
    // "首頁"不加入左側菜單點擊 push TagGroup，所以 itemName !== '首頁'
    // console.log("一層麵包被點");
    if (!tagGroup.some((e) => e.itemName == itemName) && itemName !== "首頁") {
      setTagGroup([
        ...tagGroup,
        { path: path, itemName: itemName, closable: true },
      ]);
    }
    setActiveKey(path);
    navigate(path);
  };

  const onEdit = (targetKey, action) => {
    if (tagGroup.length > 0) remove(targetKey);
  };

  const onChange = (activeKey) => {
    // console.log("點上面:", activeKey);
    setActiveKey(activeKey);
    navigate(activeKey);
    // 如果activeKey 包含 "/" 就表示是下層 menu
    // 這時候要open 的是上層 menu
    // select menu的格式是 : {上層path}_{下層path}
    if (activeKey.includes("/")) {
      setOpenMenuKey(activeKey.split("/")[0]);
      setSelectMenuKey(`${activeKey.split("/")[0]}_${activeKey.split("/")[1]}`);
    } else {
      setSelectMenuKey(activeKey);
    }
  };

  useEffect(() => {
    if (!username) {
      navigate("login");
    } else if (pathname === "/login") {
      navigate("/");
    }
  }, [pathname, username]);

  useEffect(() => {
    if (location.pathname == "/login") {
      setTagGroup([]);
    }
  }, [location.pathname]);

  return (
    <div className="app">
      <Layout>
        {width > 768 ? (
          <SideMenu
            tagGroup={tagGroup}
            setTagGroup={setTagGroup}
            setActiveKey={setActiveKey}
            selectMenuKey={selectMenuKey}
            setSelectMenuKey={setSelectMenuKey}
            dataSideMenuItems={dataSideMenuItems}
            handleItemWrapperClick={handleItemWrapperClick}
            handleItemClick={handleItemClick}
            t={t}
          />
        ) : (
          <DrawerSideMenu open={open} showDrawer={showDrawer} onClose={onClose}>
            <SideMenu
              tagGroup={tagGroup}
              setTagGroup={setTagGroup}
              setActiveKey={setActiveKey}
              selectMenuKey={selectMenuKey}
              setSelectMenuKey={setSelectMenuKey}
              dataSideMenuItems={dataSideMenuItems}
              handleItemWrapperClick={handleItemWrapperClick}
              handleItemClick={handleItemClick}
              t={t}
              onClose={onClose}
              isMobile={true}
              handleSwitchSideMenu={handleSwitchSideMenu}
            />
          </DrawerSideMenu>
        )}

        <Layout>
          <Navbar
            width={width}
            dataSideMenuItems={dataSideMenuItems}
            showDrawer={showDrawer}
            handleSwitchSideMenu={handleSwitchSideMenu}
          ></Navbar>
          <Content style={{ overflow: "auto", background: "#000" }}>
            {width > 768 ? (
              <div className="topDiv">
                <Tabs
                  hideAdd
                  tabBarGutter={4}
                  onChange={onChange}
                  activeKey={activeKey}
                  type="editable-card"
                  onEdit={onEdit}
                  closable
                >
                  {/* 如果之後要在Tab上顯示首頁的話， un commit 下一行 */}
                  {/* <TabPane tab={"首頁"} key={"/"} closable={false}></TabPane> */}
                  {tagGroup.map((tag) => (
                    <TabPane
                      tab={tag.itemName}
                      key={tag.path}
                      closable={tag.closable}
                    >
                      <div>
                        <BreadCrumbComp
                          dataSideMenuItems={dataSideMenuItems}
                          handleItemWrapperClick={handleItemWrapperClick}
                          handleItemClick={handleItemClick}
                          t={t}
                          activeKey={activeKey}
                        />
                      </div>
                    </TabPane>
                  ))}
                </Tabs>
              </div>
            ) : null}

            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
