import React, { useEffect, useState } from "react";
import {
  Form,
  Modal,
  Input,
  Switch,
  Radio,
  InputNumber,
  Divider,
  Row,
  Col,
} from "antd";

import { BtnCreateAcct, Wrapper, FormTitle, DividerWrapper } from "./Styled";

import { tabDisplayOptions, tabShowAdOptions, statusOptions } from "options";

const CreateModal = ({ handleCreate, navOptions, isVisible, setIsVisible }) => {
  const [form] = Form.useForm();
  const [radioValue, setRadioValue] = useState(0);
  const onChange = (e) => {
    console.log("Value", e);
    setRadioValue(e.target.value);
  };

  useEffect(() => {
    form.resetFields();
  }, [isVisible]);

  return (
    <Modal
      width={450}
      centered={true}
      maskClosable={false}
      footer={null}
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
    >
      <Wrapper>
        <Form
          form={form}
          labelCol={{ span: 6 }}
          autoComplete="off"
          labelWrap
          initialValues={{
            show_ad: 2,
            desplay_type: 1,
            seqno: 0,
          }}
          onFinish={(values) => {
            handleCreate({
              ...values,
            });
          }}
        >
          <FormTitle>新增代理</FormTitle>

          <Form.Item
            label={"代理帳號"}
            name="user_name"
            rules={[{ required: true, message: "請輸入代理帳號!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={"代理暱稱"}
            name="name"
            rules={[{ required: true, message: "請輸入代理暱稱!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={"代理模式"}
            name="mode"
            rules={[{ required: true, message: "請選擇代理模式" }]}
          >
            <Radio.Group onChange={onChange}>
              <Radio value={1}>CPA</Radio>
              <Radio value={2}>CPS</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label={`萬IP價格`} name="cpm_price">
            <Input addonAfter="元"></Input>
          </Form.Item>

          {/* CPA條件下 */}
          {radioValue == 1 && (
            <>
              <DividerWrapper orientation="center" plain>
                CPA
              </DividerWrapper>
              <Form.Item label={`激活返佣`} name="cpa_rebate_price">
                <Input addonAfter="元"></Input>
              </Form.Item>
              <Form.Item label={`下載扣量`} name="download_deduct">
                <Input addonAfter="%" type="number" max="100" min="0"></Input>
              </Form.Item>
            </>
          )}

          {/* CPS條件下 */}
          {radioValue == 2 && (
            <>
              <DividerWrapper orientation="center" plain>
                CPS
              </DividerWrapper>
              <Form.Item label={`返佣比例`} name="cps_rebate_rate">
                <Input addonAfter="%"></Input>
              </Form.Item>
              <DividerWrapper orientation="center" plain>
                CPS 扣量
              </DividerWrapper>
              <Row>
                <Col span={12}>
                  <Form.Item label={`VIP 首充`} name="vip_firstdeduct">
                    <Input addonAfter="%"></Input>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={`金幣首充`} name="coin_firstdeduct">
                    <Input addonAfter="%"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item label={`VIP 續充`} name="vip_otherdeduct">
                    <Input addonAfter="%"></Input>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={`金幣續充`} name="coin_otherdeduct">
                    <Input addonAfter="%"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item label={`下載扣量`} name="download_deduct">
                    <Input
                      addonAfter="%"
                      type="number"
                      max="100"
                      min="0"
                    ></Input>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <BtnCreateAcct htmlType="submit">新增</BtnCreateAcct>
        </Form>
      </Wrapper>
    </Modal>
  );
};

export default CreateModal;
