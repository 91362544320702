import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  mode: 0,
  record: {},
  invite_code: "",
  isVisible: false, // 提現密碼的彈窗
  isLogInVisible: false, //登入密碼的彈窗
  isSetTradPsd: 0, //是否是第一次提現 - 修改提現密碼
  isSetPsd: 0, //是否是第一次登入 - 修改登入密碼
  oldPsd: "", //用戶在登入頁面輸入的密碼
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state.name = action.payload;
    },
    clearUser: (state, action) => {
      state.name = initialState.name;
    },
    updateMode: (state, action) => {
      state.mode = action.payload;
    },
    updateRecord: (state, action) => {
      state.record = action.payload;
    },
    storeInviteCode: (state, action) => {
      state.invite_code = action.payload;
    },
    onModalToggle: (state, action) => {
      state.isVisible = action.payload;
    },
    onLogInModalToggle: (state, action) => {
      state.isLogInVisible = action.payload;
    },
    tradPsdStatus: (state, action) => {
      state.isSetTradPsd = action.payload;
    },
    psdStatus: (state, action) => {
      state.isSetPsd = action.payload;
    },
    recordPsd: (state, action) => {
      state.oldPsd = action.payload;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;
