import styled from "styled-components";
import { BlueButton } from "styles/component";
import {
  Button,
  Form,
  Input,
  Select,
  Space,
  Table,
  Modal,
  InputNumber,
  Col,
} from "antd";

export const Wrapper = styled.div`
  ${"" /* radio 的樣式 */}
  .ant-radio-inner::after {
    background-color: #448ef7;
  }
  .ant-radio-checked .ant-radio-inner,
  .ant-radio-inner {
    border: none;
    background: #e6e6e6;
    &:focus-visible {
      outline: none;
    }
  }
`;

export const InputWrapper = styled(Input)`
  border-radius: var(--input-border-radius);
  background: var(--input-bg-color);
  &:hover,
  &:focus,
  &:active {
    outline: none;
    border-color: transparent;
    box-shadow: none;
  }

  &.ant-input-affix-wrapper-status-error,
  input {
    background: var(--input-bg-color) !important;
  }
`;

export const InputNumberWrapper = styled(InputNumber)`
  border-radius: var(--input-border-radius);
  background: var(--input-bg-color);
  &:hover,
  &:focus,
  &:active {
    outline: none;
    border-color: transparent;
    box-shadow: none;
  }
  &.ant-input-number-focused {
    outline: none;
    border-color: transparent;
    box-shadow: none;
  }
  &.ant-input-affix-wrapper-status-error,
  input {
    background: var(--input-bg-color) !important;
  }
`;

export const InputVerifyWrapper = styled(Input)`
  width: 70%;
  margin-right: 3px;
  border-radius: var(--input-border-radius);
  background: var(--input-bg-color);
  &:hover,
  &:focus,
  &:active {
    outline: none;
    border-color: transparent;
    box-shadow: none;
  }

  &.ant-input-affix-wrapper-status-error,
  input {
    background: var(--input-bg-color) !important;
  }
`;

export const BtnCreateAcct = styled(BlueButton)`
  margin-top: 60px;
  width: 100%;
  height: 40px;
  color: #fff;
`;

export const FormTitle = styled.h2`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  ${"" /* color: #fff; */}
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
`;
export const TableContainer = styled(Table)``;
export const FormWrapper = styled(Form)``;
export const FormItem = styled(Form.Item)`
  padding: 0.102rem 0.146rem 0.102rem 0;
  margin-right: 0 !important;
`;
export const InputContainer = styled(Input)``;
export const SelectWrapper = styled(Select)`
  .ant-select-selector {
    width: 100% !important;
  }
`;
export const Option = styled(Select.Option)``;
export const SpaceWrapper = styled(Space)``;
export const ButtonWrapper = styled(Button)``;
export const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    padding-top: 20px;
    border-radius: var(--modal-content-border-radius);
  }
  .ant-modal-title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #5a5a5a;
  }
  .ant-modal-header {
    text-align: center;
    padding: 24px 0 10px 0;
    border-radius: var(--modal-header-border-radius);
  }
  .ant-modal-body {
    padding: 0 26px 24px 26px;
  }
`;

export const Value = styled.p`
  color: #448ef7 !important;
  margin: 0;
  font-size: 16px;
  margin-top: 1px;
`;

export const RefreshButton = styled.button`
  color: #000;
  align-self: end;
  padding: 0px 15px 5px 5px;
  border: none;
  background: none;
  transform: scale(0.8);
  margin-top: 2px;
`;

export const FillBtn = styled(Button)`
  background-color: #aaaaaa;
  color: #fff;
  border: none;
  margin-top: ${(props) => (props.mobile ? "67%" : 0)};
  margin-left: 8px;
  &:hover,
  &:active,
  &:focus {
    background-color: #8e8c8c;
    color: #fff;
    border: none;
  }
`;

export const AnotherCol = styled(Col)`
  margin-top: ${(props) => (props.mobile ? "13%" : 0)};
  ${"" /* position: ${(props) => (props.mobile ? "relative" : 0)}; */}
  ${"" /* left: ${(props) => (props.mobile ? "-5%" : 0)}; */}
`;

export const FirstCol = styled(Col)`
  label {
    word-break: keep-all;
  }
`;
