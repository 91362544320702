import React from "react";
import { Form, Button } from "antd";
import { useTranslation } from "react-i18next";
import {
  ModalWrapper,
  FormWrapper,
  PasswordIcon,
  ConfirmIcon,
  InputPasswordWrapper,
  ConfirmBtn,
} from "./Styled";

const TradePsdModal = ({
  isVisible,
  onCancel,
  form,
  onFinish,
  regOrderNo,
  setTradePsdStatus,
}) => {
  const { t } = useTranslation();
  return (
    <ModalWrapper
      visible={isVisible}
      onCancel={onCancel}
      footer={null}
      title={t("TradePasswordModal.MODIFY")}
      width={480}
    >
      <FormWrapper form={form} onFinish={onFinish}>
        {setTradePsdStatus !== 1 && (
          <Form.Item
            // label={"舊密碼"}
            name="old_password"
            rules={[
              {
                required: true,
                message: t("TradePasswordModal.RULE_MSG"),
              },
            ]}
          >
            <InputPasswordWrapper
              prefix={<PasswordIcon />}
              placeholder={t("TradePasswordModal.INSERT_OLD_PASSWORD")}
            />
          </Form.Item>
        )}
        <Form.Item
          // label={"新密碼"}
          name="new_password"
          hasFeedback
          rules={[
            { required: true, message: t("TradePasswordModal.RULE_MSG") },
            () => ({
              validator(_, value) {
                if (regOrderNo.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t("TradePasswordModal.TYPE_SIX_NUM"))
                );
              },
            }),
          ]}
        >
          <InputPasswordWrapper
            prefix={<PasswordIcon />}
            placeholder={t("TradePasswordModal.INSERT_NEW_PASSWORD")}
          />
        </Form.Item>
        <Form.Item
          name="confirm"
          // label="確定新密碼"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: t("TradePasswordModal.RULE_MSG"),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t("TradePasswordModal.VALIDATION_MSG"))
                );
              },
            }),
          ]}
        >
          <InputPasswordWrapper
            prefix={<ConfirmIcon />}
            placeholder={t("TradePasswordModal.CONFIRM_PASSWORD")}
          />
        </Form.Item>
        <Form.Item>
          <ConfirmBtn
            type="primary"
            htmlType="submit"
            style={{ borderRadius: "6px" }}
          >
            {t("TradePasswordModal.UPDATE")}
          </ConfirmBtn>
        </Form.Item>
      </FormWrapper>
    </ModalWrapper>
  );
};

export default TradePsdModal;
