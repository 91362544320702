import { put, take, takeEvery, call, select } from "redux-saga/effects";
import actions from "redux/action";

function* getUserName() {
  yield console.log("actions.updateUser");
}

export default function* watchUser() {
  yield takeEvery(actions.updateUser, getUserName);
}
