import styled from "styled-components";
import bg from "assets/log-in-bg.png";
import user from "assets/icons/log-in-user.png";
import key from "assets/icons/log-in-key.png";
import code from "assets/icons/log-in-code.png";
import refresh from "assets/icons/log-in-refresh.png";

import { Button, Input } from "antd";
import "App.css";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  ${"" /* background: #10041b; */}
  background-image: url(${bg});
  background-size: cover;
`;

export const LogInTitle = styled.h1`
  margin: 0;
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 40.0829px;
  line-height: 47px;
  color: var(--main-color);
`;

export const ReConfirmBtn = styled(Button)`
  padding: 0;
`;

export const LoginWrapper = styled.div`
  width: 360px;
  @media (max-width: 768px) {
    width: 260px;
  }
  ${"" /* height: 500px; */}
  ${"" /* height: 467px; */}
  padding: 36.9px 44px 38px 44px;
  @media (max-width: 768px) {
    padding: 23px 16px;
  }
  border-radius: 20px;
  background-blend-mode: lighten;
  background: #fff;

  .ant-form {
    width: 100%;
    font-family: TamilMN;
    background-color: transparent;
    color: #fff;
    .ant-input {
      padding-left: 5px;
      font-size: 16px;
      color: #10041b;
      background-color: #fff;

      ::-webkit-input-placeholder {
        color: #b7b7b7;
        font-size: 16px;
      }
    }
    .ant-form-item {
      margin-bottom: 28px;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const Title = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 25px;
  color: #000;
  margin-top: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
`;

export const LoginButton = styled(Button)`
  width: 100%;
  height: 40px;
  margin: 30px 0 5px 0;
  border-radius: 6px;
  ${
    "" /* background-image: linear-gradient(
    to top,
    #d337e8 117%,
    #f954ca 23%,
    #cf35f3 -41%
  ); */
  }
  font-family: TamilMN;
  font-size: 16px;
  color: #fff;
  font-weight: bold;

  ${
    "" /* &:hover {
    background: linear-gradient(
      to top,
      #d337e8 117%,
      #f954ca 23%,
      #cf35f3 -41%
    );
  } */
  }
`;

export const ForgetPassword = styled.div`
  height: 26px;
  font-family: TamilMN;
  font-size: 16px;
  color: #000;
  text-align: center;
  cursor: pointer;
  margin: 20px 0;
`;

export const UserIcon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${user});
  background-size: contain;
`;

export const PasswordIcon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${key});
  background-size: contain;
`;

export const CodeIcon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${code});
  background-size: contain;
`;

export const RefreshIcon = styled.div`
  width: 15px;
  height: 15px;
  background-image: url(${refresh});
  background-size: contain;
  transform: scale(1.5);
  position: relative;
  left: 10px;
  bottom: 0.05rem;
  @media (max-width: 768px) {
    bottom: 0.05rem;
  }
`;

export const InputWrapper = styled(Input)`
  border-radius: var(--input-border-radius);
  background: var(--input-bg-color);
  &.ant-input-affix-wrapper-status-error,
  input {
    background: var(--input-bg-color) !important;
  }
`;

export const InputPasswordWrapper = styled(Input.Password)`
  border-radius: var(--input-border-radius);
  background: var(--input-bg-color);
  &.ant-input-affix-wrapper-status-error,
  input {
    background: var(--input-bg-color) !important;
  }
`;
