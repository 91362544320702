import styled from "styled-components";
import bg from "assets/log-in-bg.png";
import { Layout, Menu, Avatar, Divider, Button } from "antd";
import "App.css";

const { Sider } = Layout;
export const SiderWrapper = styled(Sider)`
  height: 100%;
  background-image: url(${bg});
  background-color: red;
  background-size: cover;
  position: relative;
  &:before {
    content: "";
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
  }
  .ant-menu {
    background: transparent !important;
  }
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: var(--main-color) !important;
    color: #fff !important;
  }

  .ant-menu-item:focus-visible,
  .ant-menu-submenu-title:focus-visible {
    box-shadow: none;
    background: var(--main-color) !important;
    color: #fff !important;
  }
`;

export const CustomizedMenu = styled(Menu)`
  height: 100%;
  font-size: 16px;
`;

export const Item = styled(Menu.Item)`
  font-size: 16px;
`;

export const ItemsWrapper = styled(Menu.SubMenu)``;

export const LogoDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h5 {
    color: #fff;
    z-index: 1;
    padding: 12px 0 0 24px;
  }
`;

export const UserDiv = styled.div`
  padding: 16px 16px 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h5 {
    z-index: 1 !important;
    color: #fff;
    margin: 0;
  }
`;

export const AvatarWrapper = styled(Avatar)`
  background: #fff;
  margin-right: 10px;
`;

export const DividerDiv = styled.div`
  padding: 0 16px;
  position: ${(props) => (props.isLog ? "absolute" : "relative")};
  bottom: ${(props) => (props.isLog ? "50px" : 0)};
  width: 100%;
`;

export const DividerLine = styled(Divider)`
  margin: 11px 0 13px 0;
  border: 0.5px solid #545353;
`;

export const LogOutBtn = styled(Button)`
  position: absolute;
  width: 100%;
  bottom: 20px;
  color: #fff;
  background: transparent;
  border: none;
`;
