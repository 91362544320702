import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button } from "antd";
import {
  ModalWrapper,
  FormWrapper,
  PasswordIcon,
  ConfirmIcon,
  InputPasswordWrapper,
  ConfirmBtn,
} from "./Styled";

const LogInPsdModal = ({
  isVisible,
  onCancel,
  form,
  onFinish,
  setLogInPsdStatus,
}) => {
  const { t } = useTranslation();
  return (
    <ModalWrapper
      visible={isVisible}
      onCancel={onCancel}
      footer={null}
      title={t("LogInPasswordModal.MODIFY")}
      width={480}
    >
      <FormWrapper form={form} onFinish={onFinish}>
        {setLogInPsdStatus !== 1 && (
          <Form.Item
            // label={"舊密碼"}
            name="old_password"
            rules={[
              { required: true, message: t("LogInPasswordModal.RULE_MSG") },
            ]}
          >
            <InputPasswordWrapper
              prefix={<PasswordIcon />}
              placeholder={t("LogInPasswordModal.INSERT_OLD_PASSWORD")}
            />
          </Form.Item>
        )}
        <Form.Item
          // label={"新密碼"}
          name="new_password"
          rules={[
            { required: true, message: t("LogInPasswordModal.RULE_MSG") },
          ]}
        >
          <InputPasswordWrapper
            prefix={<PasswordIcon />}
            placeholder={t("LogInPasswordModal.INSERT_NEW_PASSWORD")}
          />
        </Form.Item>
        <Form.Item
          name="confirm"
          // label="確定新密碼"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: t("LogInPasswordModal.RULE_MSG"),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t("LogInPasswordModal.VALIDATION_MSG"))
                );
              },
            }),
          ]}
        >
          <InputPasswordWrapper
            prefix={<ConfirmIcon />}
            placeholder={t("LogInPasswordModal.CONFIRM_PASSWORD")}
          />
        </Form.Item>
        <Form.Item>
          <ConfirmBtn
            type="primary"
            htmlType="submit"
            style={{ borderRadius: "6px" }}
          >
            {t("LogInPasswordModal.UPDATE")}
          </ConfirmBtn>
        </Form.Item>
      </FormWrapper>
    </ModalWrapper>
  );
};

export default LogInPsdModal;
