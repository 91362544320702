import styled from "styled-components";
import { Form, Statistic, Modal, Card } from "antd";
import "App.css";
import { Button } from "styles/css";
import confirm from "assets/icons/confirm.png";
import key from "assets/icons/log-in-key.png";

export const OutsideWrapper = styled.div`
  padding: var(--pages-padding-top);
  ${"" /* max-width: 1366px; */};
`;

export const FormWrapper = styled(Form)`
  margin-top: 24px;
`;

export const StatisticValue = styled(Statistic)`
  .ant-statistic-content-value-int {
    color: #448ef7;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 500;
  }
`;

export const RefreshButton = styled.button`
  color: #000;
  margin-top: 5px;
  align-self: end;
  padding: 0px 15px 5px 5px;
  border: none;
  background: none;
`;

export const ApplyButton = styled.button`
  ${Button};
  padding: 3px 9px;
  gap: 10px;
  background: #f78444;
  border-radius: 4px;
  align-self: end;
  margin-bottom: 5px;
  color: #000;
  font-size: "Roboto";
  &:hover {
    background: #f17d3e;
  }
`;

export const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    border-radius: var(--modal-content-border-radius);
  }
  .ant-modal-title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #5a5a5a;
  }
  .ant-modal-header {
    text-align: center;
    padding: 24px 0 10px 0;
    border-radius: var(--modal-header-border-radius);
  }
  .ant-modal-body {
    padding: 0 86px 24px 86px;
  }
`;

export const MobileCard = styled(Card)`
  margin-top: 16px;
`;
