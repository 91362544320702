import { BrowserRouter, Routes, Route } from "react-router-dom";

import App from "App";
import Login from "page/Login";
import Unknown from "page/Unknown";
import Home from "page/Home";

import PromoLink from "page/PromoLink";
import AgentList from "page/AgentList";
import RebateDetail from "page/RebateDetail";
import IncomeDetail from "page/IncomeDetail";
import AgentDetail from "page/AgentDetail";
import WithdrawApply from "page/WithdrawApply";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="promo-link" element={<PromoLink />} />
          <Route path="agent/agent-list" element={<AgentList />} />
          {/* <Route path="report/rebate-detail" element={<RebateDetail />} /> */}
          <Route path="income-detail" element={<IncomeDetail />} />
          {/* <Route path="report/agent-detail" element={<AgentDetail />} /> */}
          <Route path="withdraw-apply" element={<WithdrawApply />} />

          <Route path="login" element={<Login />} />

          <Route path="*" element={<Unknown />} />
        </Route>
        <Route path="*" element={<Unknown />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
