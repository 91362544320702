import { css } from "styled-components";

export const BeforeAfterPseudoElement = css`
  content: "";
  position: absolute;
  box-sizing: border-box;
  user-select: none;
`;

export const Button = css`
  width: auto;
  height: auto;
  padding: 6px 10px;
  font-size: 16px;
  line-height: 19px;
  border-radius: 5px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  color: #fff;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  opacity: 0.95;
  box-shadow: none !important;
`;
