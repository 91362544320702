import React, { useEffect, useState } from "react";
import { Form, Modal, Tabs, Input, Button, Space, message } from "antd";
import actions from "redux/action";
import { selectSetTradePsd } from "redux/selector/user";
import { useDispatch, useSelector } from "react-redux";
// import { selectMode } from "redux/selector/user";
import { FormWrapper, Wrapper } from "./Styled";

const PersonalModal = ({
  isVisible,
  setIsVisible,
  record,
  handleChangePsd,
  handleChangeTradePsd,
}) => {
  // // mode: 1 (CPA), 2(CPS)
  // const mode = useSelector(selectMode);
  // 1:第一次設定 , 2: 不是第一次設定
  const setTradePsdStatus = useSelector(selectSetTradePsd);
  const { TabPane } = Tabs;
  const [current, setCurrent] = useState("1");
  const [form] = FormWrapper.useForm();
  const [tradeForm] = FormWrapper.useForm();
  const dispatch = useDispatch();
  const callback = (key) => {
    console.log(key);
    setCurrent(key);
  };

  const onReset = () => {
    form.resetFields();
  };

  const onTradeReset = () => {
    tradeForm.resetFields();
  };

  const onModalToggle = () => {
    // if (setTradePsdStatus !== 1) {
    //   dispatch(actions.onModalToggle(false));
    // } else {
    //   message.warning("首次登入，請先設定交易密碼");
    //   dispatch(actions.onModalToggle(true));
    // }
    setIsVisible(false);
  };

  useEffect(() => {
    form.resetFields();
    tradeForm.resetFields();
    // 關閉個人信息彈窗後，tab設定成第一頁
    setCurrent("1");
  }, [isVisible]);
  const regOrderNo = new RegExp("^\\d{6}$");
  // useEffect(() => {
  //   setTradePsdStatus == 1 ? setCurrent("3") : setCurrent("1");
  // }, [setTradePsdStatus]);

  return (
    <Modal
      width={450}
      centered={false}
      maskClosable={false}
      footer={null}
      visible={isVisible}
      onCancel={onModalToggle}
    >
      <Wrapper>
        <Tabs activeKey={current} onChange={callback}>
          <TabPane tab="個人信息" key="1">
            <FormWrapper>
              <Form.Item label={"代理帳號"}>{record?.user_name}</Form.Item>
              <Form.Item label={"邀請碼"}>{record?.invite_code}</Form.Item>
              <Form.Item label={"餘額"}>{record?.balance}</Form.Item>
              <Form.Item label={"代理模式"}>
                {record?.mode == 1 ? "CPA" : record?.mode == 2 ? "CPS" : "CPC"}
              </Form.Item>
              {record?.mode == 1 && (
                <Form.Item label={"激活返佣"}>
                  {record?.cpa_rebate_price}元
                </Form.Item>
              )}
              {record?.mode == 2 && (
                <Form.Item label={"返佣比例"}>
                  {record?.cps_rebate_rate}％
                </Form.Item>
              )}
              {record?.mode == 3 && (
                <Form.Item label={"萬IP"}>{record?.cpc_price}元</Form.Item>
              )}
              <Form.Item label={"累積佣金"}>
                {record?.total_commission}
              </Form.Item>
              <Form.Item label={"提現總額"}>
                {record?.withdraw_commission}
              </Form.Item>
            </FormWrapper>
          </TabPane>
          <TabPane tab="登錄密碼" key="2">
            <FormWrapper form={form} onFinish={handleChangePsd}>
              <Form.Item
                label={"舊密碼"}
                name="old_password"
                rules={[{ required: true, message: "此欄位必填" }]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label={"新密碼"}
                name="new_password"
                rules={[{ required: true, message: "此欄位必填" }]}
              >
                <Input.Password />
              </Form.Item>
              {/* <Form.Item
                label={"確定新密碼"}
                name="confirm_password"
                rules={[{ required: true, message: "此欄位必填" }]}
              >
                <Input />
              </Form.Item> */}
              <Form.Item
                name="confirm"
                label="確定新密碼"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "此欄位必填",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("新密碼與確認新密碼欄位不符")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ borderRadius: "6px" }}
                  >
                    更新
                  </Button>
                  <Button
                    htmlType="button"
                    onClick={onReset}
                    style={{ borderRadius: "6px" }}
                  >
                    重置
                  </Button>
                </Space>
              </Form.Item>
            </FormWrapper>
          </TabPane>
          <TabPane tab="交易密碼" key="3">
            <FormWrapper form={tradeForm} onFinish={handleChangeTradePsd}>
              <Form.Item
                label={"舊密碼"}
                name="old_password"
                rules={[
                  {
                    required: true,
                    message: "此欄位必填",
                  },
                ]}
              >
                <Input.Password
                  placeholder={
                    setTradePsdStatus == 1 ? "首次設定，此欄位免填" : ""
                  }
                />
              </Form.Item>
              <Form.Item
                label={"新密碼"}
                name="new_password"
                hasFeedback
                rules={[
                  { required: true, message: "此欄位必填" },
                  () => ({
                    validator(_, value) {
                      if (regOrderNo.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("請輸入6位數字"));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              {/* <Form.Item
                label={"確定新密碼"}
                name="confirm_password"
                rules={[{ required: true, message: "此欄位必填" }]}
              >
                <Input />
              </Form.Item> */}
              <Form.Item
                name="confirm"
                label="確定新密碼"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "此欄位必填",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("新密碼與確認新密碼欄位不符")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ borderRadius: "6px" }}
                  >
                    更新
                  </Button>
                  <Button
                    htmlType="button"
                    onClick={onTradeReset}
                    style={{ borderRadius: "6px" }}
                  >
                    重置
                  </Button>
                </Space>
              </Form.Item>
            </FormWrapper>
          </TabPane>
        </Tabs>
      </Wrapper>
    </Modal>
  );
};

export default PersonalModal;
