import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { selectUserName } from "redux/selector/user";
import isArray from "lodash/isArray";
import { Button } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  SiderWrapper,
  CustomizedMenu,
  Item,
  ItemsWrapper,
  UserDiv,
  AvatarWrapper,
  DividerLine,
  LogoDiv,
  DividerDiv,
  LogOutBtn,
} from "./Styled";

export default function SideMenu({
  tagGroup,
  setTagGroup,
  setActiveKey,
  selectMenuKey,
  openMenuKey,
  setSelectMenuKey,
  onOpenChange,
  dataSideMenuItems,
  handleItemWrapperClick,
  handleItemClick,
  t,
  onClose,
  isMobile,
  handleSwitchSideMenu,
}) {
  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const username = useSelector(selectUserName);
  const [activePath, setActivePath] = useState("");
  const [mode, setMode] = useState("inline");
  const [theme, setTheme] = useState("light");
  const changeMode = (value) => {
    setMode(value ? "vertical" : "inline");
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const changeTheme = (value) => {
    setTheme(value ? "dark" : "light");
  };

  const onSelectMenuKey = (e) => {
    setSelectMenuKey(e.key);
  };

  useEffect(() => {
    console.log(tagGroup.length);
    // 觀察如果今天上方的 tab 都已經關閉，就跳回首頁
    // 並且將左側 select menu 設定到首頁
    if (tagGroup.length == 0) {
      setActiveKey("/");
      setSelectMenuKey("/");
      navigate("/");
    }
  }, [tagGroup.length]);

  return (
    <div>
      {username && (
        <SiderWrapper collapsed={collapsed}>
          <CustomizedMenu
            // defaultSelectedKeys={["/"]}
            defaultOpenKeys={[""]}
            mode={mode}
            theme={theme}
            selectedKeys={selectMenuKey}
            onSelect={onSelectMenuKey}
            openKeys={openMenuKey}
            onOpenChange={onOpenChange}
            inlineCollapsed={collapsed}
          >
            <LogoDiv>
              <h5>{collapsed ? "" : t("LogIn.TITLE")}</h5>
              <Button
                type="link"
                onClick={isMobile ? onClose : toggleCollapsed}
                style={{ color: "#fff", marginTop: "5px" }}
              >
                {isMobile ? (
                  <CloseOutlined />
                ) : collapsed ? (
                  <MenuUnfoldOutlined />
                ) : (
                  <MenuFoldOutlined />
                )}
              </Button>
            </LogoDiv>
            {isMobile ? (
              <>
                <UserDiv>
                  <h5>
                    <AvatarWrapper src="https://joeschmoe.io/api/v1/random" />
                    {username}
                  </h5>
                </UserDiv>
                <DividerDiv>
                  <DividerLine />
                </DividerDiv>
              </>
            ) : null}

            {dataSideMenuItems.map(({ textKey, path, children, icon }) => {
              console.log("textKey", textKey);
              const itemName = t(`SideMenuItems.${textKey}`);
              return !isArray(children) ? (
                <>
                  <Item
                    key={path}
                    onClick={() => handleItemClick(path, itemName)}
                    icon={icon}
                  >
                    {collapsed ? "" : itemName}
                  </Item>
                </>
              ) : (
                <>
                  <ItemsWrapper
                    key={path}
                    title={collapsed ? "" : itemName}
                    icon={icon}
                  >
                    {children.map(
                      ({ textKey: childrenTextKey, path: childrenPath }) => (
                        <Item
                          key={`${path}_${childrenPath}`}
                          onClick={() =>
                            handleItemWrapperClick(
                              path,
                              childrenPath,
                              childrenTextKey
                            )
                          }
                        >
                          {t(`SideMenuItems.${childrenTextKey}`)}
                        </Item>
                      )
                    )}
                  </ItemsWrapper>
                </>
              );
            })}
            {isMobile ? (
              <>
                <DividerDiv isLog={true}>
                  <DividerLine />
                </DividerDiv>
                <LogOutBtn onClick={handleSwitchSideMenu}>登出</LogOutBtn>
              </>
            ) : null}
          </CustomizedMenu>
        </SiderWrapper>
      )}
    </div>
  );
}
