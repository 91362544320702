import React, { useState, useEffect } from "react";
import CreateModal from "./component/CreateModal";
import ModifyModal from "./component/ModifyModal";
import CreatePrePayModal from "./component/CreatePrePayModal";
import TradeListModal from "./component/TradeListModal";
import CreateNextLevelModal from "./component/CreateNextLevelModal";
import ResetPassword from "./component/ResetPassword";
import { Typography, Modal } from "antd";
import {
  SuccessNotify,
  ErrorNotify,
} from "component/Notification/Notification";

import { GreenButton, BlueButton, RedButton } from "styles/component";
import {
  OutsideWrapper,
  TableContainer,
  FormWrapper,
  FormItem,
  InputContainer,
  SelectWrapper,
  Option,
  SpaceWrapper,
  DividerLine,
  Title,
  ButtonBlock,
  DatePickerWrapper,
} from "./Styled";

import { Columns } from "./data";
import // fetchAgent,
// fetchPostAgent,
// fetchPostPrePayAgent,
// fetchPutResetPassword,
// fetchPutAgent,
// fetchAgentTradeList,
"api";
import { statusFilterOptions } from "options";

const AgentList = () => {
  const { Paragraph } = Typography;
  const [form] = FormWrapper.useForm();
  const [tableData, setTableData] = useState([]);
  const [tradeListData, setTradeListData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [successObj, setSuccessObj] = useState({});
  const [tradePagination, setTradePagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [record, setRecord] = useState("");
  const [total, setTotal] = useState(0);
  const [tradeTotal, setTradeTotal] = useState(0);
  // 上方篩選的起始時間與結束時間
  const [beginDate, setBeginDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isModifyModalVisible, setIsModifyModalVisible] = useState(false);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isCreatePrePayVisible, setIsCreatePrePayVisible] = useState(false);
  const [isCreateNextLevelVisible, setIsCreateNextLevelVisible] =
    useState(false);
  const [isRestPsdVisible, setIsRestPsdVisible] = useState(false);
  const [isTradeListVisible, setIsTradeListVisible] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleFetchList = () => {
    let requestBody = form.getFieldsValue();
    // requestBody.created_at_begin = beginDate;
    // requestBody.created_at_end = endDate;
    // fetchAgent({ ...pagination, ...requestBody }).then((res) => {
    //   setTableData(res.data);
    //   setTotal(res.page_result.total);
    // });
  };
  // 獲取資料
  const handleFetchTradeList = () => {
    // fetchAgentTradeList({ ...tradePagination, agent_id: record.id }).then(
    //   (res) => {
    //     setTradeListData(res.data);
    //     setTradeTotal(res.page_result.total);
    //   }
    // );
  };
  // 新增彈窗
  const handleCreate = (requestBody) => {
    // fetchPostAgent(requestBody)
    //   .then((response) => {
    //     initializeForm();
    //     SuccessNotify("新增成功");
    //     setIsCreateModalVisible(false);
    //     handleOk();
    //     setSuccessObj(response);
    //   })
    //   .catch((error) => {
    //     ErrorNotify("編輯失敗，請聯繫技術");
    //   });
  };

  // 新增成功後，顯示帳號密碼彈窗
  const handleOk = () => {
    setIsModalVisible(true);
  };

  // 新增成功後，關閉帳號密碼彈窗
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // 編輯內容
  const handleUpdate = (requestBody) => {
    // fetchPutAgent(requestBody, requestBody.id)
    //   .then((response) => {
    //     handleFetchList();
    //     SuccessNotify("編輯成功");
    //     setIsModifyModalVisible(false);
    //   })
    //   .catch((error) => {
    //     ErrorNotify("編輯失敗，請聯繫技術");
    //   });
  };

  // 新增預付
  const handleCreatePrePay = (requestBody) => {
    // fetchPostPrePayAgent({ ...requestBody, agent_id: record.id })
    //   .then((response) => {
    //     initializeForm();
    //     SuccessNotify("新增成功");
    //     setIsCreatePrePayVisible(false);
    //   })
    //   .catch((error) => {
    //     ErrorNotify("新增失敗，請聯繫技術");
    //   });
  };

  // 新增下級
  const handleCreateNextLevel = (requestBody) => {
    // fetchPostAgent(requestBody)
    //   .then((response) => {
    //     initializeForm();
    //     SuccessNotify("新增成功");
    //     setIsCreateNextLevelVisible(false);
    //     handleOk();
    //     setSuccessObj(response);
    //   })
    //   .catch((error) => {
    //     ErrorNotify("新增失敗，請聯繫技術");
    //   });
  };

  // 重置密碼
  const handleRestPassword = () => {
    // fetchPutResetPassword(record.id)
    //   .then((response) => {
    //     handleFetchList();
    //     SuccessNotify("重置成功");
    //     setIsRestPsdVisible(false);
    //   })
    //   .catch((error) => {
    //     ErrorNotify("編輯失敗，請聯繫技術");
    //   });
  };

  // const handleDelete = (id) => {
  //   fetchDeleteTab({ id })
  //     .then((response) => {
  //       handleFetchList();
  //       SuccessNotify({ message: "刪除成功" });
  //     })
  //     .catch((e) => {
  //       ErrorNotify({ message: "刪除失敗，請聯繫技術" });
  //     });
  // };
  // 顯示新增視窗
  const showCreateModal = () => {
    setIsCreateModalVisible(true);
  };
  // 顯示編輯視窗
  const showModifyModal = (data) => {
    setSelectedData(data);
    setIsModifyModalVisible(true);
  };
  // 顯示新增預付視窗
  const showCreatePrePayModal = (record) => {
    setRecord(record);
    setIsCreatePrePayVisible(true);
  };
  // 顯示新增下級視窗
  const showCreateNextLevelModal = (record) => {
    setRecord(record);
    setIsCreateNextLevelVisible(true);
  };
  // 顯示流水明細視窗
  const showTradeListModal = (record) => {
    setRecord(record);
    setIsTradeListVisible(true);
    // handleFetchTradeList();
  };
  // 顯示重置密碼視窗
  const showResetPsdModal = (record) => {
    setRecord(record);
    setIsRestPsdVisible(true);
  };

  const onPageUpdate = (pagination) => {
    setPagination(pagination);
  };

  const onTradePageUpdate = (tradePagination) => {
    setTradePagination(tradePagination);
  };

  const onBeginDateChange = (_, dateString) => {
    setBeginDate(dateString);
  };

  const onEndDateChange = (_, dateString) => {
    setEndDate(dateString);
  };

  const columns = Columns({
    // handleDelete,
    showCreateModal,
    handleUpdate,
    showModifyModal,
    showCreatePrePayModal,
    showCreateNextLevelModal,
    showTradeListModal,
    showResetPsdModal,
  });

  const initializeForm = () => {
    setPagination({
      current: 1,
      pageSize: 10,
    });
  };

  useEffect(() => {
    handleFetchList();
  }, [pagination]);

  useEffect(() => {
    handleFetchTradeList();
  }, [tradePagination, record]);

  return (
    <>
      <OutsideWrapper>
        {/* <DividerLine orientation="left">
          <Title level={3}>代理管理</Title>
        </DividerLine> */}

        {/* <ButtonBlock>
          <BlueButton onClick={showCreateModal}>新增</BlueButton>
        </ButtonBlock>

        <FormWrapper
          form={form}
          layout={"inline"}
          autoComplete="off"
          onFinish={initializeForm}
          initialValues={{}}
          labelWrap
          labelCol={{ span: 8 }}
        >
          <FormItem
            label="代理帳號"
            name="user_name"
            style={{ width: "2.1rem" }}
          >
            <InputContainer />
          </FormItem>
          <FormItem
            label="上級代理"
            name="parent_user_name"
            style={{ width: "1.6rem" }}
          >
            <InputContainer />
          </FormItem>
          <FormItem label="返佣狀態" name="commission_state">
            <SelectWrapper defaultValue={0}>
              {statusFilterOptions.map(({ value, label }, i) => {
                return (
                  <Option key={i} value={value}>
                    {label}
                  </Option>
                );
              })}
            </SelectWrapper>
          </FormItem>
          <FormItem label="代理狀態" name="status">
            <SelectWrapper defaultValue={0}>
              {statusFilterOptions.map(({ value, label }, i) => {
                return (
                  <Option key={i} value={value}>
                    {label}
                  </Option>
                );
              })}
            </SelectWrapper>
          </FormItem>
          <FormItem
            label="創建時間"
            name="created_at_begin"
            style={{ width: "2.3rem" }}
          >
            <DatePickerWrapper
              showTime
              onChange={onBeginDateChange}
            ></DatePickerWrapper>
          </FormItem>
          <FormItem
            label="結束時間"
            name="created_at_end"
            style={{ width: "2.3rem" }}
          >
            <DatePickerWrapper
              showTime
              onChange={onEndDateChange}
            ></DatePickerWrapper>
          </FormItem>

          <FormItem>
            <SpaceWrapper>
              <GreenButton>篩選</GreenButton>
              <RedButton
                onClick={() => {
                  form.resetFields();
                }}
              >
                重置
              </RedButton>
            </SpaceWrapper>
          </FormItem>
        </FormWrapper> */}

        <TableContainer
          scroll={{ y: "29vw" }}
          columns={columns}
          dataSource={tableData}
          onChange={onPageUpdate}
          pagination={{
            ...pagination,
            total,
          }}
        />
      </OutsideWrapper>

      <CreateModal
        isVisible={isCreateModalVisible}
        setIsVisible={setIsCreateModalVisible}
        handleCreate={handleCreate}
      />

      <ModifyModal
        isVisible={isModifyModalVisible}
        setIsVisible={setIsModifyModalVisible}
        record={selectedData}
        handleUpdate={handleUpdate}
      />

      <CreatePrePayModal
        isVisible={isCreatePrePayVisible}
        setIsVisible={setIsCreatePrePayVisible}
        handleCreate={handleCreatePrePay}
        record={record}
      />

      <TradeListModal
        isVisible={isTradeListVisible}
        setIsVisible={setIsTradeListVisible}
        // handleCreatePrePayCreate={handleCreatePrePay}
        tradeListData={tradeListData}
        onPageUpdate={onTradePageUpdate}
        pagination={tradePagination}
        total={tradeTotal}
      />

      <CreateNextLevelModal
        isVisible={isCreateNextLevelVisible}
        setIsVisible={setIsCreateNextLevelVisible}
        handleCreate={handleCreateNextLevel}
        record={record}
      />

      <ResetPassword
        isVisible={isRestPsdVisible}
        setIsVisible={setIsRestPsdVisible}
        handleUpdate={handleRestPassword}
      />

      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Paragraph copyable style={{ color: "#fff", fontSize: "20px" }}>
          代理帳號：{successObj.user_name}
        </Paragraph>
        <Paragraph copyable style={{ color: "#fff", fontSize: "20px" }}>
          代理密碼：{successObj.password}
        </Paragraph>
      </Modal>
    </>
  );
};

export default AgentList;
