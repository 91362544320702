import { Button, Space, Popconfirm, Image, Switch, Empty } from "antd";
import keyBy from "lodash/keyBy";
import { withdrawStateOptions } from "options";
import EditIcon from "assets/icons/edit.png";
import DeleteIcon from "assets/icons/delete.png";
import { timeFormat } from "functions/timeFormat";

const withdrawStateObj = keyBy(withdrawStateOptions, (e) => e.value);
// 下方表格
export const Columns = ({
  handleCancel,
  t,
  // showModifyModal,
  // handleUpdate,
  // regionData,
}) => [
  {
    title: t("WithdrawApply.ID"),
    dataIndex: "id",
    key: "id",
  },
  {
    title: t("WithdrawApply.AGENT_BALANCE"),
    dataIndex: "agent_balance",
    key: "agent_balance",
  },
  {
    title: t("WithdrawApply.WITHDRAW_AMOUNT"),
    dataIndex: "withdraw_amount",
    key: "withdraw_amount",
    render: (text, record) => {
      if (record.type == 1) {
        return Number(text)?.toFixed(2);
      } else {
        return (0).toFixed(2);
      }
    },
  },
  {
    title: t("WithdrawApply.PREPAY_BALANCE"),
    dataIndex: "withdraw_amount",
    key: "withdraw_amount",
    render: (text, record) => {
      if (record.type == 2) {
        return Number(text)?.toFixed(2);
      } else {
        return (0).toFixed(2);
      }
    },
  },
  {
    title: t("WithdrawApply.CREATED_AT"),
    dataIndex: "created_at",
    key: "created_at",
    render: (text) => timeFormat(text) || " - ",
  },
  {
    title: t("WithdrawApply.SUCCEED_AT"),
    dataIndex: "succeed_at",
    key: "succeed_at",
    render: (text) => timeFormat(text) || " - ",
  },
  {
    title: t("WithdrawApply.STATUS"),
    dataIndex: "status",
    key: "status",
    width: 60,
    render: (text) => {
      return withdrawStateObj[text]?.label ?? text;
    },
  },
  {
    title: t("WithdrawApply.REMARK"),
    dataIndex: "remark",
    key: "remark",
  },
  {
    title: t("WithdrawApply.ACTIONS"),
    dataIndex: "actions",
    key: "actions",
    width: 60,
    // render: (_, record) => {
    //   if (record.status == 1) {
    //     return (
    //       <Space size={"middle"}>
    //         {/* <Button onClick={() => props.handleShutDown}>關閉</Button> */}
    //         {/* <img
    //           src={EditIcon}
    //           alt="edit"
    //           onClick={() => showModifyModal(record)}
    //         /> */}
    //         <Popconfirm
    //           title="請確認是否取消？"
    //           okText={"確認"}
    //           cancelText={"取消"}
    //           onConfirm={() => handleCancel(record.id)}
    //         >
    //           <img src={EditIcon} alt="cancel" />
    //         </Popconfirm>
    //       </Space>
    //     );
    //   }
    // },
  },
];
