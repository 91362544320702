import React, { useEffect, useState } from "react";
import {
  Form,
  Modal,
  Input,
  Select,
  Radio,
  InputNumber,
  Divider,
  Row,
  Col,
} from "antd";

import {
  BtnCreateAcct,
  Wrapper,
  FormTitle,
  DividerWrapper,
  SelectWrapper,
  Option,
} from "./Styled";
import { tabDisplayOptions, tabShowAdOptions, statusOptions } from "options";

const CreatePrePayModal = ({
  handleCreate,
  record,
  isVisible,
  setIsVisible,
}) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [radioValue, setRadioValue] = useState(0);
  const onChange = (e) => {
    console.log("Value", e);
    setRadioValue(e.target.value);
  };

  useEffect(() => {
    form.resetFields();
  }, [isVisible]);

  return (
    <Modal
      width={450}
      centered={true}
      maskClosable={false}
      footer={null}
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
    >
      <Wrapper>
        <Form
          form={form}
          labelCol={{ span: 6 }}
          autoComplete="off"
          labelWrap
          initialValues={{
            show_ad: 2,
            desplay_type: 1,
            seqno: 0,
          }}
          onFinish={handleCreate}
        >
          <FormTitle>新增預付</FormTitle>

          <Form.Item label={"預付金額"}>
            <Input disabled defaultValue={record.prepay_balance}></Input>
          </Form.Item>

          <Form.Item label={"新增預付"} name="prepay_amount">
            <InputNumber />
          </Form.Item>

          <Form.Item label={"支付方式"} name="prepay_method">
            <Radio.Group onChange={onChange}>
              <Radio value={1}>銀行卡</Radio>
              <Radio value={2}>USDT</Radio>
            </Radio.Group>
          </Form.Item>
          {/* 銀行卡條件下 */}
          {radioValue == 1 && (
            <>
              {/* <DividerWrapper orientation="center" plain>
                銀行卡
              </DividerWrapper> */}
              <Form.Item label={`銀行名稱`} name="bank_name">
                <Input></Input>
              </Form.Item>
              {/* <DividerWrapper orientation="center" plain>
                CPS 扣量
              </DividerWrapper> */}
              <Form.Item label={`用戶姓名`} name="bank_accountname">
                <Input addonAfter="%"></Input>
              </Form.Item>
              <Form.Item label={`銀行帳號`} name="bank_accountno">
                <Input addonAfter="%"></Input>
              </Form.Item>
              <Form.Item label={`備註`} name="bank_remark">
                <Input addonAfter="%"></Input>
              </Form.Item>
            </>
          )}

          {/* CPA條件下 */}
          {radioValue == 2 && (
            <>
              {/* <DividerWrapper orientation="center" plain>
                CPA
              </DividerWrapper> */}
              <Form.Item label={`通道類型`} name="usdt_type">
                <Select>
                  <Option value={1}>ERC20</Option>
                  <Option value={2}>TRC20</Option>
                </Select>
              </Form.Item>
              <Form.Item label={`USDT地址`} name="usdt_address">
                <Input></Input>
              </Form.Item>
              <Form.Item label={`備註`} name="bank_remark">
                <Input></Input>
              </Form.Item>
            </>
          )}

          <BtnCreateAcct htmlType="submit">新增</BtnCreateAcct>
        </Form>
      </Wrapper>
    </Modal>
  );
};

export default CreatePrePayModal;
