import React from "react";
import { BreadCrumbItem } from "./Styled";
import { Breadcrumb } from "antd";
const BreadCrumbComp = ({
  dataSideMenuItems,
  handleItemWrapperClick,
  handleItemClick,
  t,
  activeKey,
}) => {
  return (
    <>
      <Breadcrumb>
        {/* <Breadcrumb.Item href="/">儀表板</Breadcrumb.Item> */}
        {dataSideMenuItems.map(({ textKey, path, children }) => {
          const itemName = t(`SideMenuItems.${textKey}`);
          if (activeKey.includes("/") && activeKey !== "/") {
            if (path == activeKey.split("/")[0]) {
              return children.map(
                ({ textKey: childrenTextKey, path: childrenPath }) => {
                  if (childrenPath == activeKey.split("/")[1]) {
                    return (
                      <>
                        <BreadCrumbItem isTop={true}>{itemName}</BreadCrumbItem>
                        <BreadCrumbItem
                          key={`${path}_${childrenPath}`}
                          onClick={() =>
                            handleItemWrapperClick(
                              path,
                              childrenPath,
                              childrenTextKey
                            )
                          }
                        >
                          {t(`SideMenuItems.${childrenTextKey}`)}
                        </BreadCrumbItem>
                      </>
                    );
                  }
                }
              );
            }
          } else {
            if (path == activeKey) {
              return (
                <BreadCrumbItem
                  key={path}
                  onClick={() => handleItemClick(path, itemName)}
                >
                  {itemName}
                </BreadCrumbItem>
              );
            }
          }
        })}
      </Breadcrumb>
    </>
  );
};

export default BreadCrumbComp;
