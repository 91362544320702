import React, { useEffect, useState } from "react";
import { Form, Modal, Input, Row, Col, Radio } from "antd";
import { DividerWrapper } from "../TradeListModal/Styled";

import { BtnCreateAcct, Wrapper, FormTitle } from "./Styled";
import { FormText } from "../../Styled";
import { tabDisplayOptions, tabShowAdOptions, statusOptions } from "options";

const ModifyModal = ({ record, isVisible, setIsVisible, handleUpdate }) => {
  const [form] = Form.useForm();
  const [radioValue, setRadioValue] = useState(0);

  const onChange = (e) => {
    console.log("Value", e);
    setRadioValue(e.target.value);
  };

  useEffect(() => {
    form.setFieldsValue({
      id: record.id,
      user_name: record.user_name,
      name: record.name,
      mode: record.mode,
      cpm_price: record.cpm_price,
      cps_rebate_rate: record.cps_rebate_rate,
      vip_firstdeduct: record.vip_firstdeduct,
      coin_firstdeduct: record.coin_firstdeduct,
      vip_otherdeduct: record.vip_otherdeduct,
      coin_otherdeduct: record.coin_otherdeduct,
      download_deduct: record.download_deduct,
      cpa_rebate_price: record.cpa_rebate_price,
      download_deduct: record.download_deduct,
    });
    setRadioValue(record.mode);
  }, [record]);

  return (
    <Modal
      width={450}
      centered={true}
      maskClosable={false}
      footer={null}
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
    >
      <Wrapper>
        <Form
          form={form}
          labelCol={{ span: 6 }}
          labelWrap
          autoComplete="off"
          initialValues={{}}
          onFinish={handleUpdate}
        >
          <FormTitle>編輯代理</FormTitle>

          {/* 這項目不顯示，單純用來存 ID */}
          <Form.Item name="id" style={{ display: "none" }}></Form.Item>

          <Form.Item label={"代理帳號"} name="user_name">
            {/* <Input  disabled /> */}
            <FormText>{record.user_name}</FormText>
          </Form.Item>

          <Form.Item label={"代理暱稱"} name="name">
            <Input />
          </Form.Item>

          <Form.Item label={"代理模式"} name="mode">
            {/* <Radio.Group onChange={onChange} disabled>
              <Radio value={1}>CPA</Radio>
              <Radio value={2}>CPS</Radio>
            </Radio.Group> */}
            <FormText>{record.mode == 1 ? "CPA" : "CPS"}</FormText>
          </Form.Item>
          <Form.Item label={`萬IP價格`} name="cpm_price">
            <Input addonAfter="元"></Input>
          </Form.Item>

          {/* CPA條件下 */}
          {radioValue == 1 && (
            <>
              <DividerWrapper orientation="center" plain>
                CPA
              </DividerWrapper>
              <Form.Item label={`激活返佣`} name="cpa_rebate_price">
                <Input addonAfter="元"></Input>
              </Form.Item>
              <Form.Item label={`下載扣量`} name="download_deduct">
                <Input addonAfter="%" type="number" max="100" min="0"></Input>
              </Form.Item>
            </>
          )}

          {/* CPS條件下 */}
          {radioValue == 2 && (
            <>
              <DividerWrapper orientation="center" plain>
                CPS
              </DividerWrapper>
              <Form.Item label={`返佣比例`} name="cps_rebate_rate">
                <Input addonAfter="%"></Input>
              </Form.Item>
              <DividerWrapper orientation="center" plain>
                CPS 扣量
              </DividerWrapper>
              <Row>
                <Col span={12}>
                  <Form.Item label={`VIP 首充`} name="vip_firstdeduct">
                    <Input addonAfter="%"></Input>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={`金幣首充`} name="coin_firstdeduct">
                    <Input addonAfter="%"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item label={`VIP 續充`} name="vip_otherdeduct">
                    <Input addonAfter="%"></Input>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={`金幣續充`} name="coin_otherdeduct">
                    <Input addonAfter="%"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item label={`下載扣量`} name="download_deduct">
                    <Input
                      addonAfter="%"
                      type="number"
                      max="100"
                      min="0"
                    ></Input>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <BtnCreateAcct htmlType="submit">更新</BtnCreateAcct>
        </Form>
      </Wrapper>
    </Modal>
  );
};

export default ModifyModal;
